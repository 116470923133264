import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

const AuthContext = createContext();
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/';

const AuthProvider = ({ children }) => {
  // State to hold the authentication token
  const [token, setToken_] = useState(sessionStorage.getItem("token"));

  // Function to set the authentication token
  const setToken = async (newToken) => {
    setToken_(newToken);
  };

  useEffect(() => {
    if (token) {
      console.log("set token header", token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      sessionStorage.setItem('token', token);
    } else {
      console.log("delete token header");
      delete axios.defaults.headers.common["Authorization"];
      sessionStorage.removeItem('token');
    }
  }, [token]);

  // Memoized value of the authentication context
  const contextValue = useMemo(
    () => ({
      token,
      setToken,
    }),
    [token]
  );

  // Provide the authentication context to the children components
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;