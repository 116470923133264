import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";


const POPaymentForm = () => {
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = useState(null);
  const [purchaseData, setPurchaseData] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);

  const navigate = useNavigate();

  const formID = searchParams.get("form_id");
  const purchaseID = searchParams.get("purchase");

  useEffect(() => {
    if (formID > 0) {
      setDataLoaded(false);
      fetchData(formID);
    }
    else {
      fetchPurchaseData(purchaseID);
      setDataLoaded(true);
    }
  }, [formID]);

  const fetchPurchaseData = async (formID) => {
    try {
      const response = await axios.get(
        `/purchase-order/${formID}`
      );
      const results = await response.data.data;
      setPurchaseData(results);
      setDataLoaded(true);
    } catch (error) {
      console.error(error);
    }
  }

  const fetchData = async (formID) => {
    try {
      const response = await axios.get(
        `/purchase-order-payments/${formID}`
      );
      const results = await response.data.data;
      setFormData(results);
      setDataLoaded(true);
    } catch (error) {
      console.error(error);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let userFormData = {
      date: e.target.date.value,
      amount: e.target.amount.value,
      purchase_id: purchaseID,
      payment_method: e.target.payment_method.value,
      note: e.target.note.value
    }
    if (formData) {
      update(userFormData);
    } else {
      add(userFormData);
      // setFormData(null);
    }
  };

  // Create a new user
  const add = async (userData) => {
    try {
      const response = await axios.post('/purchase-order-payments', userData);
      const data = await response.data;
      console.log(data);
      toast(data.message);
      if (data.status) {
        navigate('/dashboard/purchases');
      }
    } catch (error) {
      console.error('Error adding user:', error);
    }
  };

  // Update an existing user
  const update = async (userData) => {
    try {
      const response = await axios.put(`/purchase-order-payments/${formData.id}`, userData);
      const data = await response.data;
      console.log(data);
      if (data.status) {
        toast("Purchase Order Payment updated");
      }
      // setFormData(data.data);
    } catch (error) {
      console.error('Error updating:', error);
    }
  }

  return (
    <>
      <Link to='/dashboard/purchases' className="btn btn-primary" >Back</Link>
      <hr />
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">Purchase Order Payment Form - (Reference: {formData ? formData.purchase.reference : (purchaseData ? purchaseData.reference : '')})</h6>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="date">Date <span className="text-danger">*</span></label>
                <input type="date" className="form-control" defaultValue={formData ? formData.date : ''} name="date" required />
              </div>
              <div className="form-group col-md-6">
                <label>Due Amount: </label>
                <p>{formData ? formData.purchase.due_amount : (purchaseData ? purchaseData.due_amount : '')} </p>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="amount">Amount <span className="text-danger">*</span></label>
                <input type="number" className="form-control" defaultValue={formData ? formData.amount : ''} name="amount" required />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="payment_method">Payment Method <span className="text-danger">*</span></label>
                <select className="form-control" name="payment_method" defaultValue={formData ? formData.payment_method : ''} required>
                  <option value="Cash">Cash</option>
                  <option value="Credit Card">Credit Card</option>
                  <option value="Bank Transfer">Bank Transfer</option>
                  <option value="Cheque">Cheque</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label htmlFor="note">Notes</label>
                <textarea className="form-control" defaultValue={formData ? formData.note : ''} name="note" ></textarea>
              </div>
            </div>
            <button type="submit" className="btn btn-primary">{formID > 0 ? ('Update') : ('Add')}</button>
          </form>
        </div>
      </div>
    </>
  )
}

export default POPaymentForm;