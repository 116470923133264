import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { buildApiErrors } from "../../helpers/common";
import { UserContext } from "../../provider/userDataProvider";

const Profile = () => {
  const userData = useContext(UserContext);
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    if (userData) {
      setFormData({ name: userData.user_data.name, email: userData.user_data.email });
    }
  }, [userData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const latestFormData = {
      name: e.target.inputName.value,
      email: e.target.inputEmail.value
    }
    try {
      const response = await axios.post(`/auth/update`, latestFormData);
      const data = await response.data;
      console.log(data);
      toast(data.message);
      if (data.status) {
        setFormData(latestFormData);
      }
    } catch (error) {
      console.error('Error updating:', error);
      toast.error(buildApiErrors(error));
    }
  };

  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    const latestFormData = {
      password: e.target.npwd.value,
      password_confirmation: e.target.upwd.value
    }
    try {
      const response = await axios.post(`/auth/reset-password`, latestFormData);
      const data = await response.data;
      console.log(data);
      toast(data.message);
    } catch (error) {
      console.error('Error updating:', error);
      toast.error(buildApiErrors(error));
    }
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">Profile Settings</h6>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="inputName">Name</label>
                    <input type="text" className="form-control" defaultValue={formData ? formData.name : ''} name="inputName" required />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="inputEmail">Email</label>
                    <input type="text" className="form-control" defaultValue={formData ? formData.email : ''} name="inputEmail" required />
                  </div>
                </div>
                <button type="submit" className="btn btn-primary">Update Profile</button>
              </form>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">Password Settings</h6>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmitPassword}>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label htmlFor="npwd">New Password</label>
                    <input type="password" className="form-control" name="npwd" required />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label htmlFor="upwd">Confirm Password</label>
                    <input type="password" className="form-control" name="upwd" required />
                  </div>
                </div>
                <button type="submit" className="btn btn-primary">Update Password</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Profile;