import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

const UserForm = () => {
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = useState(null);
  const [rolesData, setRolesData] = useState([]);
  const [statusData, setStatusData] = useState([{ "id": 1, "name": "Active" }, { "id": 2, "name": "InActive" }]);
  const [roleSelected, setRoleSelected] = useState("");
  const [statusSelected, setStatusSelected] = useState(1);
  const navigate = useNavigate();

  const userID = searchParams.get("form_id");
  useEffect(() => {
    fetchRoles();
    if (userID > 0) {
      fetchUser(userID);
    }
  }, [userID]);

  const fetchUser = async (userID) => {
    try {
      const response = await axios.get(
        `/user/${userID}`
      );
      setFormData(response.data.data);
      setRoleSelected(response.data.data.roles[0].id);
      setStatusSelected(response.data.data.is_active);
      // console.log(roleSelected, statusSelected, response.data.data.is_active);
    } catch (error) {
      console.error(error);
    }
  }

  const fetchRoles = async () => {
    try {
      const response = await axios.post(`/role/all`);
      setRolesData(response.data.data);
      if (userID < 1) {
        setRoleSelected(response.data.data[0].id);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let userFormData = {
      name: e.target.inputName.value,
      email: e.target.inputEmail.value,
      is_active: statusSelected,
      role: roleSelected
    }
    if (formData) {
      updateUser(userFormData);
    } else {
      userFormData.password = e.target.inputPassword.value;
      userFormData.password_confirmation = e.target.inputPasswordConfirm.value;
      addUser(userFormData);
      setFormData(null);
    }
  };

  // Create a new user
  const addUser = async (userData) => {
    try {
      const response = await axios.post('/user', userData);
      const data = await response.data;
      console.log(data);
      toast(data.message);
      if (data.status) {
        navigate('/dashboard/users');
      }
    } catch (error) {
      console.error('Error adding user:', error);
    }
  };

  // Update an existing user
  const updateUser = async (userData) => {
    try {
      const response = await axios.put(`/user/${formData.id}`, userData);
      const data = await response.data;
      console.log(data);
      if (data.status) {
        toast("User is updated");
        navigate('/dashboard/users');
      }
      // setFormData(data.data);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  return (
    <>
      <Link to='/dashboard/users' className="btn btn-primary" >Back</Link>
      <hr />
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">User Form</h6>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="inputName">Name</label>
                <input type="text" className="form-control" defaultValue={formData ? formData.name : ''} name="inputName" placeholder="Name" required />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="inputEmail">Email</label>
                <input type="email" className="form-control" defaultValue={formData ? formData.email : ''} name="inputEmail" placeholder="Email" required />
              </div>
            </div>
            {!formData &&
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="inputPassword">Password</label>
                  <input type="password" className="form-control" name="inputPassword" required />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="inputPasswordConfirm">Confirm Password</label>
                  <input type="password" className="form-control" name="inputPasswordConfirm" required />
                </div>
              </div>
            }
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="role">Role <span className="text-danger">*</span></label>
                <select className="form-control" value={roleSelected} onChange={e => setRoleSelected(e.target.value)} name="role" required>
                  {
                    rolesData.map((e, key) => {
                      return <option key={e.name} value={e.id}>{e.name}</option>;
                    })
                  }
                </select>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="inputStatus">Status</label>
                <select className="form-control" value={statusSelected} onChange={e => setStatusSelected(e.target.value)} name="inputStatus" required>
                  {
                    statusData.map((e, key) => {
                      return <option key={e.name} value={e.id}>{e.name}</option>;
                    })
                  }
                </select>
              </div>
            </div>
            <button type="submit" className="btn btn-primary">{formData ? ('Update') : ('Add')}</button>
          </form>
        </div>
      </div>
    </>
  )
}

export default UserForm;