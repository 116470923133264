import axios from 'axios';
import { useState } from 'react';
import AsyncSelect from 'react-select/async';

const ProductSearch = (props) => {

  // const [options, setOptions] = useState(null);
  const [searchData, setSearchData] = useState(null);

  // this function only finds the item from all the data that has the same id
  // that comes from the parent component (my case - formik initial)
  const findByValue = (fullData, specificValue) => {
    return fullData.find((e) => e.id === specificValue);
  };

  const loadData = async (searchVal) => {
    // load your data using query HERE
    console.log(searchVal);
    searchVal = searchVal.trim();
    if (searchVal.length == 0 || searchVal.length > 2) {
      return fetchData(1, 20, searchVal);
    }
    else {
      return null;
    }
  };

  const fetchData = async (page, size, search) => {
    try {
      const response = await axios.post(
        `/product/all?page=${page}&limit=${size}`, { search: search }
      );
      const data = await response.data.data.data;
      setSearchData(data);
      const asyncOptions = data.map((row, i) => {
        return { value: row.id, label: row.product_name };
      });
      console.log(asyncOptions);
      // setOptions(asyncOptions);
      return asyncOptions;
    } catch (error) {
      console.log(error);
    }
  };

  const filterOptions = (options, inputValue) => {
    const candidate = inputValue.toLowerCase();
    return options.filter(({ label }) => label.toLowerCase().includes(candidate));
  };

  const handleSelectItem = (event) => {
    console.log(event);
    const filtered = findByValue(searchData, event.value);
    console.log(filtered);
    props.func(filtered);
  };

  return (
    <>
      <div className='row'>
        <div className='col'>
          <AsyncSelect
            placeholder="Type product name or code...."
            isClearable={false}
            defaultOptions
            loadOptions={loadData}
            onChange={handleSelectItem}
          />
        </div>
      </div><br />
    </>
  );
}

export default ProductSearch;