import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import { Link, useOutletContext } from "react-router-dom";

const Sales = () => {
  const checkPermissionExists = useOutletContext();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // const [deleted, setDeleted] = useState([]);

  const fetchData = async (page, size = perPage, search = '') => {
    try {
      setLoading(true);
      const response = await axios.post(
        `/sale/all?page=${page}&limit=${size}`, { search: search }
      );

      setData(response.data.data.data);
      setTotalRows(response.data.data.total);
      setLoading(false);
      setDataLoaded(true);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("useeffect", loading, dataLoaded);
    setDataLoaded(false);
    fetchData(1);
  }, []);

  const handleDelete = useCallback(
    row => async () => {
      console.log(row);
      if (window.confirm("Are you sure want to delete " + row.reference + "?")) {
        try {
          await axios.delete(`/sale/${row.id}`);
          fetchData(1);
        } catch (error) {
          console.error(error);
        }
      }
    },
    [currentPage, perPage, totalRows]
  );

  const columns = useMemo(
    () => [
      {
        name: "Reference",
        selector: row => row.reference,
        wrap: true
        //sortable: true
      },
      {
        name: "Customer",
        selector: row => row.customer_details.customer_name,
        wrap: true
        //sortable: true
      },
      {
        name: "Status",
        selector: row => row.status,
        // wrap: true
        //sortable: true
      },
      {
        name: "Total (Rs)",
        selector: row => row.total_amount,
        // wrap: true
        //sortable: true
      },
      {
        name: "Paid (Rs)",
        selector: row => row.paid_amount,
        // wrap: true
        //sortable: true
      },
      {
        name: "Due (Rs)",
        selector: row => row.due_amount,
      },
      {
        name: "Payment Status",
        selector: row => row.payment_status,
        wrap: true
      },
      {
        name: "Created On",
        selector: row => row.created_at,
        format: row => moment(row.created_at).format('YYYY-MM-DD kk:mm:ss'),
        wrap: true
      },
      {
        name: "Actions",
        // eslint-disable-next-line react/button-has-type
        cell: row =>
          <div className="btn-group" role="group">
            <button type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
              {/* <i className="fas fa-ellipsis-v"></i> */}
            </button>
            <div className="dropdown-menu">
              {checkPermissionExists('edit_sales') &&
                <>
                  <Link to={`/dashboard/sale-form?form_id=${row.id}`} className="dropdown-item" >Edit</Link>
                </>
              }
              {checkPermissionExists('show_sales') &&
                <>
                  <Link to={`/dashboard/sale?form_id=${row.id}`} className="dropdown-item" >View</Link>
                </>
              }
              {checkPermissionExists('access_purchase_payments') &&
                <>
                  {row.due_amount > 0 &&
                    <Link to={`/dashboard/sale-payments-form?purchase=${row.id}&form_id=0`} className="dropdown-item" >Add Payment</Link>
                  }
                  <Link to={`/dashboard/sale-payments?form_id=${row.id}`} className="dropdown-item" >Show Payments</Link>
                </>
              }
              {checkPermissionExists('delete_sales') && <a className="dropdown-item" onClick={handleDelete(row)}>Delete</a>}
            </div>
          </div>
      }
    ],
    [handleDelete]
  );

  const handlePageChange = page => {
    if (dataLoaded) {
      console.log("handlePageChange", loading);

      fetchData(page);
      setCurrentPage(page);
    }
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    if (dataLoaded) {
      console.log("handlePerRowsChange", loading);
      fetchData(page, newPerPage);
      setPerPage(newPerPage);
    }
  };

  const handleSearch = (event) => {
    let searchVal = event.target.value;
    console.log(searchVal);
    setSearch(searchVal);
    searchVal = searchVal.trim();
    if (searchVal.length == 0 || searchVal.length > 2) {
      fetchData(1, perPage, searchVal);
    }
  };

  return (
    <>
      <div className="card shadow mb-4">
        <div className="card-body">
          <div className="row">
            <div className="col">
              {checkPermissionExists('create_sales') && <Link to='/dashboard/sale-form?form=0' className="btn btn-primary" >Add Sale</Link>}
            </div>
            <div className="col offset-md-4">
              <input className="form-control" type="text" name="inputSearch" value={search} placeholder="Search" onChange={handleSearch} />
            </div>
          </div>
          <DataTable
            title="Sales List"
            columns={columns}
            data={data}
            progressPending={loading}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </div>
      </div>
    </>
  )
}

export default Sales;