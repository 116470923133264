import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { buildApiErrors } from "../../helpers/common";

const RoleForm = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { roleData } = location.state;
  const [formData, setFormData] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const navigate = useNavigate();
  const permissionsList = [
    "show_total_stats",
    "show_notifications",
    "show_month_overview",
    "show_weekly_sales_purchases",
    "show_monthly_cashflow",
    "access_user_management",
    "edit_own_profile",
    "access_products",
    "show_products",
    "create_products",
    "edit_products",
    "delete_products",
    "access_product_categories",
    "print_barcodes",
    "access_adjustments",
    "create_adjustments",
    "show_adjustments",
    "edit_adjustments",
    "delete_adjustments",
    "access_quotations",
    "create_quotations",
    "show_quotations",
    "edit_quotations",
    "delete_quotations",
    "send_quotation_mails",
    "create_quotation_sales",
    "access_expenses",
    "create_expenses",
    "edit_expenses",
    "delete_expenses",
    "access_expense_categories",
    "access_customers",
    "create_customers",
    "show_customers",
    "edit_customers",
    "delete_customers",
    "access_suppliers",
    "create_suppliers",
    "show_suppliers",
    "edit_suppliers",
    "delete_customers",
    "access_sales",
    "create_sales",
    "show_suppliers",
    "edit_sales",
    "delete_sales",
    "create_pos_sales",
    "access_sale_payments",
    "access_sale_returns",
    "create_sale_returns",
    "show_sale_returns",
    "edit_sale_returns",
    "delete_sale_returns",
    "access_sale_return_payments",
    "access_purchases",
    "create_purchases",
    "show_purchases",
    "edit_purchases",
    "delete_purchases",
    "access_purchase_payments",
    "access_purchase_returns",
    "create_purchase_returns",
    "show_purchase_returns",
    "edit_purchase_returns",
    "delete_purchase_returns",
    "access_purchase_return_payments",
    "access_currencies",
    "create_currencies",
    "edit_currencies",
    "delete_currencies",
    "access_reports",
    "access_settings"
  ];

  const formID = searchParams.get("form_id");

  useEffect(() => {
    console.log("useeffect", roleData);
    setFormData(roleData);
    if (roleData) {
      const tempPermissions = roleData.permissions.map((obj, i) => {
        return obj.name;
      });
      setPermissions(tempPermissions);
    }
  }, [formID]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const latestFormData = {
      name: e.target.name.value,
      permissions: permissions
    }
    if (formData) {
      update(latestFormData);
    } else {
      add(latestFormData);
      setFormData(null);
    }
  };

  // Create a new user
  const add = async (userData) => {
    try {
      const response = await axios.post('/role', userData);
      const data = await response.data;
      console.log(data);
      toast(data.message);
      if (data.status) {
        navigate('/dashboard/roles-permissions');
      }
    } catch (error) {
      console.error('Error adding user:', error);
      toast.error(buildApiErrors(error));
    }
  };

  // Update an existing user
  const update = async (userData) => {
    try {
      const response = await axios.put(`/role/${formData.id}`, userData);
      const data = await response.data;
      console.log(data);
      toast(data.message);
    } catch (error) {
      console.error('Error updating user:', error);
      toast.error(buildApiErrors(error));
    }
  };

  const checkPermissionExists = (permission) => {
    // console.log(permission);
    if (permissions) {
      return permissions.indexOf(permission) > -1 ? true : false;
    }
    else {
      return false;
    }
  }

  const updatePermission = async (e) => {
    const fieldValue = e.target.value;
    let tempRows = [...permissions]; // avoid direct state mutation
    console.log(tempRows);
    const tempIndex = tempRows.indexOf(fieldValue);
    // console.log(tempRows, fieldValue, e.target.checked, tempIndex);
    if (e.target.checked) {
      // add permission
      if (tempIndex < 0) {
        console.log("adding");
        tempRows.push(fieldValue);
        setPermissions(tempRows);
      }
    }
    else {
      if (tempIndex > -1) {
        console.log("deleting");
        if (tempRows.splice(tempIndex, 1)) {
          setPermissions(tempRows);
          // console.log(permissions, tempRows);
        }
      }
    }
  };

  const selectAllPermissions = async (e) => {
    if (e.target.checked) {
      // add all permissions
      setPermissions(permissionsList);
    }
    else {
      setPermissions(null);
    }
  };

  return (
    <>
      <Link to='/dashboard/roles-permissions' className="btn btn-primary" >Back</Link>
      <hr />
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">Role Form</h6>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div class="form-group">
              <label for="name">Role Name <span class="text-danger">*</span></label>
              <input class="form-control" type="text" name="name" defaultValue={formData ? formData.name : ''} required />
            </div>

            <hr />

            <div class="form-group">
              <label for="permissions">
                Permissions <span class="text-danger">*</span>
              </label>
            </div>

            <div class="form-group">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="select-all" onChange={(e) => selectAllPermissions(e)} />
                <label class="custom-control-label" for="select-all">Give All Permissions</label>
              </div>
            </div>

            <div class="row">
              {/* <!-- Dashboard Permissions --> */}
              <div class="col-lg-4 col-md-6 mb-3">
                <div class="card h-100 border-0 shadow">
                  <div class="card-header">
                    Dashboard
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="show_total_stats" name="permissions[]"
                            value="show_total_stats" checked={checkPermissionExists('show_total_stats')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="show_total_stats">Total Stats</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="show_notifications" name="permissions[]"
                            value="show_notifications" checked={checkPermissionExists('show_notifications')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="show_notifications">Notifications</label>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="show_month_overview" name="permissions[]"
                            value="show_month_overview" checked={checkPermissionExists('show_month_overview')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="show_month_overview">Month Overview</label>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="show_weekly_sales_purchases" name="permissions[]"
                            value="show_weekly_sales_purchases" checked={checkPermissionExists('show_weekly_sales_purchases')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="show_weekly_sales_purchases">Weekly Sales & Purchases</label>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="show_monthly_cashflow" name="permissions[]"
                            value="show_monthly_cashflow" checked={checkPermissionExists('show_monthly_cashflow')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="show_monthly_cashflow">Monthly Cashflow</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- User Management Permission --> */}
              <div class="col-lg-4 col-md-6 mb-3">
                <div class="card h-100 border-0 shadow">
                  <div class="card-header">
                    User Mangement
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="access_user_management" name="permissions[]"
                            value="access_user_management" checked={checkPermissionExists('access_user_management')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="access_user_management">Access</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="edit_own_profile" name="permissions[]"
                            value="edit_own_profile" checked={checkPermissionExists('edit_own_profile')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="edit_own_profile">Own Profile</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Products Permission --> */}
              <div class="col-lg-4 col-md-6 mb-3">
                <div class="card h-100 border-0 shadow">
                  <div class="card-header">
                    Products
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="access_products" name="permissions[]"
                            value="access_products" checked={checkPermissionExists('access_products')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="access_products">Access</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="show_products" name="permissions[]"
                            value="show_products" checked={checkPermissionExists('show_products')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="show_products">View</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="create_products" name="permissions[]"
                            value="create_products" checked={checkPermissionExists('create_products')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="create_products">Create</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="edit_products" name="permissions[]"
                            value="edit_products" checked={checkPermissionExists('edit_products')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="edit_products">Edit</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="delete_products" name="permissions[]"
                            value="delete_products" checked={checkPermissionExists('delete_products')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="delete_products">Delete</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="access_product_categories" name="permissions[]"
                            value="access_product_categories" checked={checkPermissionExists('access_product_categories')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="access_product_categories">Category</label>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="print_barcodes" name="permissions[]"
                            value="print_barcodes" checked={checkPermissionExists('print_barcodes')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="print_barcodes">Print Barcodes</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Adjustments Permission --> */}
              <div class="col-lg-4 col-md-6 mb-3">
                <div class="card h-100 border-0 shadow">
                  <div class="card-header">
                    Adjustments
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="access_adjustments" name="permissions[]"
                            value="access_adjustments" checked={checkPermissionExists('access_adjustments')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="access_adjustments">Access</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="create_adjustments" name="permissions[]"
                            value="create_adjustments" checked={checkPermissionExists('create_adjustments')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="create_adjustments">Create</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="show_adjustments" name="permissions[]"
                            value="show_adjustments" checked={checkPermissionExists('show_adjustments')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="show_adjustments">View</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="edit_adjustments" name="permissions[]"
                            value="edit_adjustments" checked={checkPermissionExists('edit_adjustments')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="edit_adjustments">Edit</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="delete_adjustments" name="permissions[]"
                            value="delete_adjustments" checked={checkPermissionExists('delete_adjustments')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="delete_adjustments">Delete</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Quotations Permission --> */}
              <div class="col-lg-4 col-md-6 mb-3">
                <div class="card h-100 border-0 shadow">
                  <div class="card-header">
                    Quotaions
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="access_quotations" name="permissions[]"
                            value="access_quotations" checked={checkPermissionExists('access_quotations')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="access_quotations">Access</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="create_quotations" name="permissions[]"
                            value="create_quotations" checked={checkPermissionExists('create_quotations')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="create_quotations">Create</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="show_quotations" name="permissions[]"
                            value="show_quotations" checked={checkPermissionExists('show_quotations')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="show_quotations">View</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="edit_quotations" name="permissions[]"
                            value="edit_quotations" checked={checkPermissionExists('edit_quotations')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="edit_quotations">Edit</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="delete_quotations" name="permissions[]"
                            value="delete_quotations" checked={checkPermissionExists('delete_quotations')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="delete_quotations">Delete</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="send_quotation_mails" name="permissions[]"
                            value="send_quotation_mails" checked={checkPermissionExists('send_quotation_mails')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="send_quotation_mails">Send Email</label>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="create_quotation_sales" name="permissions[]"
                            value="create_quotation_sales" checked={checkPermissionExists('create_quotation_sales')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="create_quotation_sales">Sale From Quotation</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Expenses Permission --> */}
              <div class="col-lg-4 col-md-6 mb-3">
                <div class="card h-100 border-0 shadow">
                  <div class="card-header">
                    Expenses
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="access_expenses" name="permissions[]"
                            value="access_expenses" checked={checkPermissionExists('access_expenses')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="access_expenses">Access</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="create_expenses" name="permissions[]"
                            value="create_expenses" checked={checkPermissionExists('create_expenses')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="create_expenses">Create</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="edit_expenses" name="permissions[]"
                            value="edit_expenses" checked={checkPermissionExists('edit_expenses')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="edit_expenses">Edit</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="delete_expenses" name="permissions[]"
                            value="delete_expenses" checked={checkPermissionExists('delete_expenses')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="delete_expenses">Delete</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="access_expense_categories" name="permissions[]"
                            value="access_expense_categories" checked={checkPermissionExists('access_expense_categories')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="access_expense_categories">Category</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Customers Permission --> */}
              <div class="col-lg-4 col-md-6 mb-3">
                <div class="card h-100 border-0 shadow">
                  <div class="card-header">
                    Customers
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="access_customers" name="permissions[]"
                            value="access_customers" checked={checkPermissionExists('access_customers')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="access_customers">Access</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="create_customers" name="permissions[]"
                            value="create_customers" checked={checkPermissionExists('create_customers')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="create_customers">Create</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="show_customers" name="permissions[]"
                            value="show_customers" checked={checkPermissionExists('show_customers')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="show_customers">View</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="edit_customers" name="permissions[]"
                            value="edit_customers" checked={checkPermissionExists('edit_customers')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="edit_customers">Edit</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="delete_customers" name="permissions[]"
                            value="delete_customers" checked={checkPermissionExists('delete_customers')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="delete_customers">Delete</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Suppliers Permission --> */}
              <div class="col-lg-4 col-md-6 mb-3">
                <div class="card h-100 border-0 shadow">
                  <div class="card-header">
                    Suppliers
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="access_suppliers" name="permissions[]"
                            value="access_suppliers" checked={checkPermissionExists('access_suppliers')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="access_suppliers">Access</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="create_suppliers" name="permissions[]"
                            value="create_suppliers" checked={checkPermissionExists('create_suppliers')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="create_suppliers">Create</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="show_suppliers" name="permissions[]"
                            value="show_suppliers" checked={checkPermissionExists('show_suppliers')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="show_suppliers">View</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="edit_suppliers" name="permissions[]"
                            value="edit_suppliers" checked={checkPermissionExists('edit_suppliers')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="edit_suppliers">Edit</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="delete_customers" name="permissions[]"
                            value="delete_customers" checked={checkPermissionExists('delete_customers')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="delete_customers">Delete</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Sales Permission --> */}
              <div class="col-lg-4 col-md-6 mb-3">
                <div class="card h-100 border-0 shadow">
                  <div class="card-header">
                    Sales
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="access_sales" name="permissions[]"
                            value="access_sales" checked={checkPermissionExists('access_sales')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="access_sales">Access</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="create_sales" name="permissions[]"
                            value="create_sales" checked={checkPermissionExists('create_sales')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="create_sales">Create</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="show_sales" name="permissions[]"
                            value="show_suppliers" checked={checkPermissionExists('show_sales')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="show_sales">View</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="edit_sales" name="permissions[]"
                            value="edit_sales" checked={checkPermissionExists('edit_sales')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="edit_sales">Edit</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="delete_sales" name="permissions[]"
                            value="delete_sales" checked={checkPermissionExists('delete_sales')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="delete_sales">Delete</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="create_pos_sales" name="permissions[]"
                            value="create_pos_sales" checked={checkPermissionExists('create_pos_sales')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="create_pos_sales">POS System</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="access_sale_payments" name="permissions[]"
                            value="access_sale_payments" checked={checkPermissionExists('access_sale_payments')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="access_sale_payments">Payments</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Sale Returns Permission --> */}
              <div class="col-lg-4 col-md-6 mb-3">
                <div class="card h-100 border-0 shadow">
                  <div class="card-header">
                    Sale Returns
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="access_sale_returns" name="permissions[]"
                            value="access_sale_returns" checked={checkPermissionExists('access_sale_returns')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="access_sale_returns">Access</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="create_sale_returns" name="permissions[]"
                            value="create_sale_returns" checked={checkPermissionExists('create_sale_returns')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="create_sale_returns">Create</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="show_sale_returns" name="permissions[]"
                            value="show_sale_returns" checked={checkPermissionExists('show_sale_returns')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="show_sale_returns">View</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="edit_sale_returns" name="permissions[]"
                            value="edit_sale_returns" checked={checkPermissionExists('edit_sale_returns')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="edit_sale_returns">Edit</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="delete_sale_returns" name="permissions[]"
                            value="delete_sale_returns" checked={checkPermissionExists('delete_sale_returns')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="delete_sale_returns">Delete</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="access_sale_return_payments" name="permissions[]"
                            value="access_sale_return_payments" checked={checkPermissionExists('access_sale_return_payments')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="access_sale_return_payments">Payments</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Purchases Permission --> */}
              <div class="col-lg-4 col-md-6 mb-3">
                <div class="card h-100 border-0 shadow">
                  <div class="card-header">
                    Purchases
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="access_purchases" name="permissions[]"
                            value="access_purchases" checked={checkPermissionExists('access_purchases')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="access_purchases">Access</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="create_purchases" name="permissions[]"
                            value="create_purchases" checked={checkPermissionExists('create_purchases')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="create_purchases">Create</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="show_purchases" name="permissions[]"
                            value="show_purchases" checked={checkPermissionExists('show_purchases')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="show_purchases">View</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="edit_purchases" name="permissions[]"
                            value="edit_purchases" checked={checkPermissionExists('edit_purchases')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="edit_purchases">Edit</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="delete_purchases" name="permissions[]"
                            value="delete_purchases" checked={checkPermissionExists('delete_purchases')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="delete_purchases">Delete</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="access_purchase_payments" name="permissions[]"
                            value="access_purchase_payments" checked={checkPermissionExists('access_purchase_payments')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="access_purchase_payments">Payments</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Purchases Returns Permission --> */}
              <div class="col-lg-4 col-md-6 mb-3">
                <div class="card h-100 border-0 shadow">
                  <div class="card-header">
                    Purchase Returns
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="access_purchase_returns" name="permissions[]"
                            value="access_purchase_returns" checked={checkPermissionExists('access_purchase_returns')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="access_purchase_returns">Access</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="create_purchase_returns" name="permissions[]"
                            value="create_purchase_returns" checked={checkPermissionExists('create_purchase_returns')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="create_purchase_returns">Create</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="show_purchase_returns" name="permissions[]"
                            value="show_purchase_returns" checked={checkPermissionExists('show_purchase_returns')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="show_purchase_returns">View</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="edit_purchase_returns" name="permissions[]"
                            value="edit_purchase_returns" checked={checkPermissionExists('edit_purchase_returns')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="edit_purchase_returns">Edit</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="delete_purchase_returns" name="permissions[]"
                            value="delete_purchase_returns" checked={checkPermissionExists('delete_purchase_returns')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="delete_purchase_returns">Delete</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="access_purchase_return_payments" name="permissions[]"
                            value="access_purchase_return_payments" checked={checkPermissionExists('access_purchase_return_payments')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="access_purchase_return_payments">Payments</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Currencies Permission --> */}
              <div class="col-lg-4 col-md-6 mb-3">
                <div class="card h-100 border-0 shadow">
                  <div class="card-header">
                    Currencies
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="access_currencies" name="permissions[]"
                            value="access_currencies" checked={checkPermissionExists('access_currencies')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="access_currencies">Access</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="create_currencies" name="permissions[]"
                            value="create_currencies" checked={checkPermissionExists('create_currencies')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="create_currencies">Create</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="edit_currencies" name="permissions[]"
                            value="edit_currencies" checked={checkPermissionExists('edit_currencies')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="edit_currencies">Edit</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="delete_currencies" name="permissions[]"
                            value="delete_currencies" checked={checkPermissionExists('delete_currencies')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="delete_currencies">Delete</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Reports --> */}
              <div class="col-lg-4 col-md-6 mb-3">
                <div class="card h-100 border-0 shadow">
                  <div class="card-header">
                    Reports
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="access_reports" name="permissions[]"
                            value="access_reports" checked={checkPermissionExists('access_reports')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="access_reports">Access</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Settings --> */}
              <div class="col-lg-4 col-md-6 mb-3">
                <div class="card h-100 border-0 shadow">
                  <div class="card-header">
                    Settings
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-6">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                            id="access_settings" name="permissions[]"
                            value="access_settings" checked={checkPermissionExists('access_settings')} onChange={(e) => updatePermission(e)} />
                          <label class="custom-control-label" for="access_settings">Access</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button type="submit" className="btn btn-primary">{formData ? ('Update') : ('Add')}</button>
          </form>
        </div>
      </div>
    </>
  )
}

export default RoleForm;