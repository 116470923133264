import SideBar from "../components/dashboard/SideBar";
import Navbar from "../components/dashboard/NavBar";
import { Outlet, useNavigate } from "react-router-dom";
import Footer from "../components/dashboard/Footer";
import { useEffect, useState } from "react";
import httpClient from "../provider/httpClient";
import { UserContext } from "../provider/userDataProvider";
import { useAuth } from "../provider/authProvider";

const DashboardLayout = () => {
  const { setToken } = useAuth();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(false);
  const [userPermissions, setUserPermissions] = useState(false);

  const [openSideBarToggle, setOpenSideBarToggle] = useState("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");

  useEffect(() => {
    setUserRoles();
  }, []);

  const setUserRoles = async () => {
    // var config = {
    //   headers: { 'Authorization': 'Bearer ' + token }
    // }
    try {
      const response = await httpClient.post("/auth/me", {});
      console.log(response);
      const results = await response.data;
      if (results.status) {
        setUserData(results);
        setUserPermissions(results.roles_data.permissions);
        sessionStorage.setItem("user_data", JSON.stringify(results));
      }
      else {
        sessionStorage.setItem("user_data", null);
      }
    } catch (error) {
      sessionStorage.setItem("user_data", null);
    }
  }

  const OpenSideBar = () => {
    if (openSideBarToggle === "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion") {
      setOpenSideBarToggle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled");
    }
    else {
      setOpenSideBarToggle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");
    }
  }

  const handleLogout = () => {
    setToken();
    sessionStorage.setItem("user_data", null);
    navigate("/", { replace: true });
  };

  const checkPermissionExists = (permission) => {
    // console.log(permission);
    // console.log(userPermissions);
    if (userPermissions) {
      return userPermissions.indexOf(permission) > -1 ? true : false;
    }
    else {
      return false;
    }
  }

  return (
    <>
      <UserContext.Provider value={userData}>
        {/* <!-- Page Wrapper --> */}
        <div id="wrapper">

          {/* <!-- Sidebar --> */}
          <SideBar openSideBarToggle={openSideBarToggle} OpenSideBar={OpenSideBar} checkPermissionExists={checkPermissionExists} />
          {/* <!-- End of Sidebar --> */}

          {/* <!-- Content Wrapper --> */}
          <div id="content-wrapper" className="d-flex flex-column">
            {/* <!-- Main Content --> */}
            <div id="content">
              {/* <!-- Topbar --> */}
              <Navbar OpenSideBar={OpenSideBar} userData={userData} />
              {/* <!-- End of Topbar --> */}
              {/* <!-- Begin Page Content --> */}

              <div className="container-fluid">
                <Outlet context={checkPermissionExists} />
              </div>
              {/* <!-- /.container-fluid --> */}
            </div>
            {/* <!-- End of Main Content --> */}

            {/* <!-- Footer --> */}
            <Footer />
            {/* <!-- End of Footer --> */}

          </div>
          {/* <!-- End of Content Wrapper --> */}

        </div>
        {/* <!-- End of Page Wrapper --> */}

        {/* <!-- Scroll to Top Button--> */}
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>

        {/* <!-- Logout Modal--> */}
        <div className="modal fade" id="logoutModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
          aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">Select "Logout" below if you are ready to end your current session.</div>
              <div className="modal-footer">
                <button className="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                <button className="btn btn-primary" type="button" onClick={handleLogout} data-dismiss="modal">Logout</button>
                {/* <a className="btn btn-primary" href="login.html">Logout</a> */}
              </div>
            </div>
          </div>
        </div>
      </UserContext.Provider>
    </>

  );
}

export default DashboardLayout;