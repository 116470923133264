import { forwardRef, useEffect, useState } from "react";
import "./SalePOSPrint.css";

const SalePOSPrint = forwardRef((props, ref) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(props.rows);
    console.log("pos print", props.rows);
  }, [props.rows]);

  return (
    <>
      <div id="receipt-data" style={{ maxWidth: "400px", margin: "0 auto" }} ref={ref}>
        <div className="centered">
          <h2 style={{ marginBottom: "5px" }}>{props.businessData ? props.businessData.company_name : ''}</h2>
          <p style={{ fontSize: "11px", lineHeight: "15px", marginTop: "0" }}>
            {props.businessData ? props.businessData.company_email : ''}, {props.businessData ? props.businessData.company_phone : ''}
            <br />{props.businessData ? props.businessData.company_address : ''}
          </p>
        </div>
        <p>
          Date: {props.formData ? props.formData.date : ''}<br />
          Reference: {props.formData ? props.formData.reference : ''}<br />
          Name: {props.formData ? props.formData.customer_details.customer_name : ''}
        </p>
        <table className="table-data">
          <tbody>
            {rows.map(function (row) {
              return (<tr>
                <td colSpan="2">
                  {row.product.product_name}
                  ({row.quantity} x {row.price})
                </td>
                <td style={{ textAlign: "right", verticalAlign: "bottom" }}>{row.sub_total}</td>
              </tr>);
            })}
            <tr>
              <th colSpan="2" style={{ textAlign: "left" }}>Tax ({props.formData ? props.formData.tax_percentage : ''}%)</th>
              <th style={{ textAlign: "right" }}>{props.formData ? props.formData.tax_amount : ''}</th>
            </tr>
            <tr>
              <th colSpan="2" style={{ textAlign: "left" }}>Discount ({props.formData ? props.formData.discount_percentage : ''}%)</th>
              <th style={{ textAlign: "right" }}>{props.formData ? props.formData.discount_amount : ''}</th>
            </tr>
            <tr>
              <th colSpan="2" style={{ textAlign: "left" }}>Shipping</th>
              <th style={{ textAlign: "right" }}>{props.formData ? props.formData.shipping_amount : ''}</th>
            </tr>
            <tr>
              <th colSpan="2" style={{ textAlign: "left" }}>Grand Total</th>
              <th style={{ textAlign: "right" }}>{props.formData ? props.formData.total_amount : ''}</th>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr style={{ backgroundColor: "#ddd" }}>
              <td className="centered" style={{ padding: "5px" }}>
                Paid By: {props.formData ? props.formData.payment_method : ''}
              </td>
              <td className="centered" style={{ padding: "5px" }}>
                Amount: {props.formData ? props.formData.paid_amount : ''}
              </td>
            </tr>
          </tbody>
        </table>
        <center style={{ marginTop: "5px", marginBottom: "5px" }}>Powered by Reyns POS</center>
      </div >
    </>
  )
})

export default SalePOSPrint;
