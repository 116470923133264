import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../provider/userDataProvider';

const SideBar = ({ openSideBarToggle, OpenSideBar, checkPermissionExists }) => {
  const userData = useContext(UserContext);
  const [businessInfo, setBusinessInfo] = useState(false);

  useEffect(() => {
    if (userData) {
      console.log("side bar", userData);
      // setUserPermissions(userData.roles_data.permissions);
      setBusinessInfo(userData.tenant_data);
    }
  }, [userData]);

  // const checkPermissionExists = (permission) => {
  //   // console.log(permission);
  //   // console.log(userPermissions);
  //   if (userPermissions) {
  //     return userPermissions.indexOf(permission) > -1 ? true : false;
  //   }
  //   else {
  //     return false;
  //   }
  // }

  return (
    <>
      <ul className={openSideBarToggle} id="accordionSidebar">
        {/* <!-- Sidebar - Brand --> */}
        <Link to="/dashboard" className="sidebar-brand d-flex align-items-center justify-content-center">
          <div className="sidebar-brand-icon rotate-n-15">
            <i className="fas fa-cloud-upload-alt"></i>
          </div>
          <div className="sidebar-brand-text mx-3">{businessInfo.company_name}</div>
        </Link>

        {/* <!-- Divider --> */}
        <hr className="sidebar-divider my-0" />

        {/* <!-- Nav Item - Dashboard --> */}
        <li className="nav-item active">
          <Link to='/dashboard' className="nav-link">
            <i className="fas fa-fw fa-tachometer-alt"></i>
            <span>Dashboard</span>
          </Link>
        </li>
        {/* <!-- Divider --> */}
        <hr className="sidebar-divider" />

        {/* <!-- Heading --> */}
        <div className="sidebar-heading">
          Features
        </div>

        {/* <!-- Nav Item - Pages Collapse Menu --> */}

        {/* Products section */}
        {checkPermissionExists('access_products') &&
          <li className="nav-item">
            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseProducts"
              aria-expanded="true" aria-controls="collapseProducts">
              <i className="fas fa-fw fa-folder"></i>
              <span>Products</span>
            </a>
            <div id="collapseProducts" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
              <div className="bg-white py-2 collapse-inner rounded">
                {checkPermissionExists('access_product_categories') && <Link to='/dashboard/product-categories' className="collapse-item" >Categories</Link>}
                {checkPermissionExists('create_products') && <Link to='/dashboard/product-form?form=0' className="collapse-item" >Create Product</Link>}
                <Link to='/dashboard/products' className="collapse-item" >All Products</Link>
              </div>
            </div>
          </li>
        }
        {/* Stock Adjustments section */}
        {checkPermissionExists('access_adjustments') &&
          <li className="nav-item">
            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseStockAdjustments"
              aria-expanded="true" aria-controls="collapseStockAdjustments">
              <i className="fas fa-fw fa-folder"></i>
              <span>Stock Adjustments</span>
            </a>
            <div id="collapseStockAdjustments" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
              <div className="bg-white py-2 collapse-inner rounded">
                {checkPermissionExists('create_adjustments') && <Link to='/dashboard/adjustment-form?form=0' className="collapse-item" >Create Adjustment</Link>}
                <Link to='/dashboard/adjustments' className="collapse-item" >All Adjustments</Link>
              </div>
            </div>
          </li>
        }
        {/* Purchase Orders section */}
        {checkPermissionExists('access_purchases') &&
          <li className="nav-item">
            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePurchases"
              aria-expanded="true" aria-controls="collapsePurchases">
              <i className="fas fa-fw fa-folder"></i>
              <span>Purchase Orders</span>
            </a>
            <div id="collapsePurchases" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
              <div className="bg-white py-2 collapse-inner rounded">
                {checkPermissionExists('create_purchases') && <Link to='/dashboard/purchase-order-form?form=0' className="collapse-item" >Create Purchase</Link>}
                <Link to='/dashboard/purchases' className="collapse-item" >All Purchases</Link>
              </div>
            </div>
          </li>
        }

        {/* Sales section */}
        {checkPermissionExists('access_sales') &&
          <li className="nav-item">
            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseSales"
              aria-expanded="true" aria-controls="collapseSales">
              <i className="fas fa-fw fa-folder"></i>
              <span>Sales</span>
            </a>
            <div id="collapseSales" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
              <div className="bg-white py-2 collapse-inner rounded">
                {checkPermissionExists('create_sales') && <Link to='/dashboard/sale-form?form=0' className="collapse-item" >Create Sale</Link>}
                <Link to='/dashboard/sales' className="collapse-item" >All Sales</Link>
              </div>
            </div>
          </li>
        }

        {/* Expenses section */}
        {checkPermissionExists('access_expenses') &&
          <li className="nav-item">
            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseExpenses"
              aria-expanded="true" aria-controls="collapseExpenses">
              <i className="fas fa-fw fa-folder"></i>
              <span>Expenses</span>
            </a>
            <div id="collapseExpenses" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
              <div className="bg-white py-2 collapse-inner rounded">
                {checkPermissionExists('access_expense_categories') && <Link to='/dashboard/expense-categories' className="collapse-item" >Expense Categories</Link>}
                {checkPermissionExists('create_expenses') && <Link to='/dashboard/expense-form?form=0' className="collapse-item" >Create Expense</Link>}
                <Link to='/dashboard/expenses' className="collapse-item" >All Expenses</Link>
              </div>
            </div>
          </li>
        }

        {/* parties section */}
        {(checkPermissionExists('access_customers') || checkPermissionExists('access_suppliers')) &&
          <li className="nav-item">
            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages"
              aria-expanded="true" aria-controls="collapsePages">
              <i className="fas fa-fw fa-folder"></i>
              <span>Parties</span>
            </a>
            <div id="collapsePages" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
              <div className="bg-white py-2 collapse-inner rounded">
                {/* <h6 className="collapse-header">Login Screens:</h6> */}
                <Link to='/dashboard/customers' className="collapse-item" >Customers</Link>
                <Link to='/dashboard/suppliers' className="collapse-item" >Suppliers</Link>
                {/* <a className="collapse-item" href="forgot-password.html">Forgot Password</a>
                <div className="collapse-divider"></div>
                <h6 className="collapse-header">Other Pages:</h6>
                <a className="collapse-item" href="404.html">404 Page</a>
                <a className="collapse-item" href="blank.html">Blank Page</a> */}
              </div>
            </div>
          </li>
        }
        {/* users section */}
        {checkPermissionExists('access_user_management') &&
          <li className="nav-item">
            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUserManagePages"
              aria-expanded="true" aria-controls="collapseUserManagePages">
              <i className="fas fa-fw fa-folder"></i>
              <span>Staffs</span>
            </a>
            <div id="collapseUserManagePages" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
              <div className="bg-white py-2 collapse-inner rounded">
                {/* <h6 className="collapse-header">Login Screens:</h6> */}
                <Link to='/dashboard/user-form?form=0' className="collapse-item" >Create Staff</Link>
                <Link to='/dashboard/users' className="collapse-item" >All Staffs</Link>
                <Link to='/dashboard/roles-permissions' className="collapse-item" >Roles and Permissions</Link>
                {/* <a className="collapse-item" href="forgot-password.html">Forgot Password</a>
                <div className="collapse-divider"></div>
                <h6 className="collapse-header">Other Pages:</h6>
                <a className="collapse-item" href="404.html">404 Page</a>
                <a className="collapse-item" href="blank.html">Blank Page</a> */}
              </div>
            </div>
          </li>
        }

        {/* system settings section */}
        {checkPermissionExists('access_settings') &&
          <li className="nav-item">
            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseSettingsMenu"
              aria-expanded="true" aria-controls="collapseSettingsMenu">
              <i className="fas fa-fw fa-folder"></i>
              <span>Settings</span>
            </a>
            <div id="collapseSettingsMenu" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
              <div className="bg-white py-2 collapse-inner rounded">
                {/* <h6 className="collapse-header">Login Screens:</h6> */}
                {checkPermissionExists('access_units') && <Link to='/dashboard/units' className="collapse-item" >Units</Link>}
                <Link to='/dashboard/setting-form' className="collapse-item" >System Settings</Link>
              </div>
            </div>
          </li>
        }

        {/* <!-- Divider --> */}
        <hr className="sidebar-divider d-none d-md-block" />

        {/* <!-- Sidebar Toggler (Sidebar) --> */}
        <div className="text-center d-none d-md-inline">
          <button className="rounded-circle border-0" id="sidebarToggle" onClick={OpenSideBar}></button>
        </div>

        {/* <!-- Sidebar Message -->
          <div className="sidebar-card d-none d-lg-flex">
            <img className="sidebar-card-illustration mb-2" src="img/undraw_rocket.svg" alt="..." />
            <p className="text-center mb-2"><strong>SB Admin Pro</strong> is packed with premium features, components, and more!</p>
            <a className="btn btn-success btn-sm" href="https://startbootstrap.com/theme/sb-admin-pro">Upgrade to Pro!</a>
          </div> */}

      </ul>
    </>
  );
}

export default SideBar;