import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

const SupplierForm = () => {
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = useState(null);
  const navigate = useNavigate();

  const supplierID = searchParams.get("form_id");
  useEffect(() => {
    if (supplierID > 0) {
      fetchSupplier(supplierID);
    }
  }, [supplierID]);

  const fetchSupplier = async (supplierID) => {
    const response = await axios.get(
      `/supplier/${supplierID}`
    );
    setFormData(response.data.data);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const supplierFormData = {
      supplier_name: e.target.inputName.value,
      supplier_email: e.target.inputEmail.value,
      supplier_phone: e.target.inputPhone.value,
      country: e.target.inputCountry.value,
      city: e.target.inputCity.value,
      address: e.target.inputAddress.value
    }
    if (formData) {
      updateSupplier(supplierFormData);
    } else {
      addSupplier(supplierFormData);
    }
    setFormData(null);
  };

  // Create a new user
  const addSupplier = async (userData) => {
    try {
      const response = await axios.post('/supplier', userData);
      const data = await response.data;
      console.log(data);
      toast(data.message);
      if (data.status) {
        navigate('/dashboard/suppliers');
      }
    } catch (error) {
      console.error('Error adding user:', error);
    }
  };

  // Update an existing user
  const updateSupplier = async (userData) => {
    try {
      const response = await axios.put(`/supplier/${formData.id}`, userData);
      const data = await response.data;
      console.log(data);
      setFormData(data.data);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  return (
    <>
      <Link to='/dashboard/suppliers' className="btn btn-primary" >Back</Link>
      <hr />
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">Supplier Form</h6>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="inputName">Name</label>
                <input type="text" className="form-control" defaultValue={formData ? formData.supplier_name : ''} name="inputName" placeholder="Name" required />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="inputEmail">Email</label>
                <input type="email" className="form-control" defaultValue={formData ? formData.supplier_email : ''} name="inputEmail" placeholder="Email" required />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="inputPhone">Phone Number</label>
                <input type="text" className="form-control" name="inputPhone" defaultValue={formData ? formData.supplier_phone : ''} placeholder="Phone Number" required />
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="inputCountry">Country</label>
                <input type="text" className="form-control" defaultValue={formData ? formData.country : ''} name="inputCountry" />
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="inputCity">City</label>
                <input type="text" className="form-control" defaultValue={formData ? formData.city : ''} name="inputCity" />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="inputAddress">Address</label>
              <input type="text" className="form-control" defaultValue={formData ? formData.address : ''} name="inputAddress" placeholder="1234 Main St" />
            </div>
            <button type="submit" className="btn btn-primary">{formData ? ('Update') : ('Add')}</button>
          </form>
        </div>
      </div>
    </>
  )
}

export default SupplierForm;