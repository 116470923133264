import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import { Link, useOutletContext } from "react-router-dom";

const ProductCategory = () => {
  const checkPermissionExists = useOutletContext();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // const [deleted, setDeleted] = useState([]);

  const fetchProductCategorys = async (page, size = perPage, search = '') => {
    try {
      setLoading(true);
      const response = await axios.post(
        `/product-category/all?page=${page}&limit=${size}`, { search: search }
      );

      setData(response.data.data.data);
      setTotalRows(response.data.data.total);
      setLoading(false);
      setDataLoaded(true);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("useeffect", loading, dataLoaded);
    setDataLoaded(false);
    fetchProductCategorys(1);
  }, []);

  const handleDelete = useCallback(
    row => async () => {
      console.log(row);
      try {
        await axios.delete(`/product-category/${row.id}`);
        fetchProductCategorys(1);
      } catch (error) {
        console.error(error);
      }
    },
    [currentPage, perPage, totalRows]
  );

  const columns = useMemo(
    () => [
      {
        name: "Code",
        selector: row => row.category_code,
        wrap: true
        //sortable: true
      },
      {
        name: "Name",
        selector: row => row.category_name,
        wrap: true
      },
      {
        name: "Created On",
        selector: row => row.created_at,
        format: row => moment(row.created_at).format('YYYY-MM-DD kk:mm:ss'),
        wrap: true
      },
      {
        name: "Actions",
        // eslint-disable-next-line react/button-has-type
        cell: row => <span>
          {
            checkPermissionExists("access_product_categories") && <>
              <Link to={`/dashboard/product-category-form?form_id=${row.id}`} ><i className="fas fa-edit" style={{ fontSize: "30px", color: "green", marginRight: "15px" }}></i></Link>
              <i className='fas fa-trash-alt' style={{ fontSize: "30px", color: "red" }} onClick={handleDelete(row)}></i>
            </>
          }
        </span >
      }
    ],
    [handleDelete]
  );

  const handlePageChange = page => {
    if (dataLoaded) {
      console.log("handlePageChange", loading);

      fetchProductCategorys(page);
      setCurrentPage(page);
    }
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    if (dataLoaded) {
      console.log("handlePerRowsChange", loading);
      fetchProductCategorys(page, newPerPage);
      setPerPage(newPerPage);
    }
  };

  const handleSearch = (event) => {
    let searchVal = event.target.value;
    console.log(searchVal);
    setSearch(searchVal);
    searchVal = searchVal.trim();
    if (searchVal.length == 0 || searchVal.length > 2) {
      fetchProductCategorys(1, perPage, searchVal);
    }
  };

  return (
    <>
      <div className="card shadow mb-4">
        <div className="card-body">
          <div className="row">
            <div className="col">
              {
                checkPermissionExists("access_product_categories") &&
                <Link to='/dashboard/product-category-form?form=0' className="btn btn-primary" >Add Product Category</Link>
              }
            </div>
            <div className="col offset-md-4">
              <input className="form-control" type="text" name="inputSearch" value={search} placeholder="Search" onChange={handleSearch} />
            </div>
          </div>
          <DataTable
            title="Product Category List"
            columns={columns}
            data={data}
            progressPending={loading}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            selectableRows
            onSelectedRowsChange={({ selectedRows }) => console.log(selectedRows)}
          />
        </div>
      </div>
    </>
  )
}

export default ProductCategory;