import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import { Link, useOutletContext, useSearchParams } from "react-router-dom";

const POPayments = () => {
  const [searchParams] = useSearchParams();
  const checkPermissionExists = useOutletContext();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [purchaseData, setPurchaseData] = useState(null);

  const formID = parseInt(searchParams.get("form_id"));

  // const [deleted, setDeleted] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `/purchase-order-payments/all`, { purchase_id: formID }
      );

      setData(response.data.data);
      setPurchaseData(response.data.data[0].purchase);
      setLoading(false);
      setDataLoaded(true);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("useeffect", loading, dataLoaded);
    setDataLoaded(false);
    fetchData();
  }, []);

  const handleDelete = useCallback(
    row => async () => {
      console.log(row);
      try {
        await axios.delete(`/purchase-order-payments/${row.id}`);
        fetchData();
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const columns = useMemo(
    () => [
      {
        name: "date",
        selector: row => row.date
      },
      {
        name: "Reference",
        selector: row => row.reference
      },
      {
        name: "Amount",
        selector: row => row.amount
      },
      {
        name: "Payment Method",
        selector: row => row.payment_method
      },
      {
        name: "Created On",
        selector: row => row.created_at,
        format: row => moment(row.created_at).format('YYYY-MM-DD kk:mm:ss'),
        wrap: true
      }
    ],
    [handleDelete]
  );

  return (
    <>
      <Link to='/dashboard/purchases' className="btn btn-primary" >Back</Link>
      <hr />
      <div className="card shadow mb-4">
        <div className="card-body">
          <div className="row">
            <div className="col">
              Reference: {purchaseData ? purchaseData.reference : ''}
            </div>
          </div>
          <DataTable
            title="Purchase Order Payments List"
            columns={columns}
            data={data}
          />
        </div>
      </div>
    </>
  )
}

export default POPayments;