import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import ProductSearch from "../product/ProductSearch";
import { calculateRow, formatNumber } from "../../helpers/common";


const PurchaseForm = () => {
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = useState({ "id": 0, "discount_percentage": 0, "tax_percentage": 0, "shipping_amount": 0.00, "tax_amount": 0.00, "discount_amount": 0.00, "date": "", "supplier_id": "", "paid_amount": 0.00, "payment_method": "Cash", "status": "Pending" });
  const [rows, setRows] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [discountTypeData, setDiscountTypeData] = useState([{ "id": "fixed", "name": "Fixed" }, { "id": "percentage", "name": "Percentage" }]);
  const [suppliersData, setSuppliersData] = useState([]);
  // const [supplierSelected, setSupplierSelected] = useState("");
  const [grandTotal, setGrandTotal] = useState(0.00);
  const [formCalFields, setFormCalFields] = useState({ "discount_percentage": 0, "tax_percentage": 0, "shipping_amount": 0 });
  // const [grandSubTotal, setGrandSubTotal] = useState(0.00);

  const navigate = useNavigate();

  const formID = searchParams.get("form_id");

  useEffect(() => {
    fetchSuppliers();
    if (formID > 0) {
      setDataLoaded(false);
      fetchData(formID);
    }
    else {
      setDataLoaded(true);
    }
  }, [formID]);

  useEffect(() => {
    console.log("rows updated: ", rows, formCalFields);
    calculateSummary();
  }, [rows, formCalFields]);

  useEffect(() => {
    console.log("formdata updated: ", formData, dataLoaded);
  }, [formData]);

  const fetchSuppliers = async () => {
    try {
      const response = await axios.post(`/supplier/all?limit=100`);
      const data = response.data.data.data;
      // console.log(data);
      setSuppliersData(data);
      // if (formID < 1) {
      //   setSupplierSelected(data[0].id);
      // }
    } catch (error) {
      console.error(error);
    }
  }

  const fetchData = async (formID) => {
    try {
      const response = await axios.get(
        `/purchase-order/${formID}`
      );
      const results = await response.data.data;
      setFormData(results);
      setRows(results.purchase_details);
      setGrandTotal(results.total_amount);
      // let grandSubTotal = 0;
      // (results.purchase_details).map((e, i) => {
      //   grandSubTotal = grandSubTotal + e.sub_total;
      //   return grandSubTotal;
      // });
      // setGrandSubTotal(grandSubTotal);
      console.log("setting initial rows ", results.purchase_details);
      setDataLoaded(true);
    } catch (error) {
      console.error(error);
    }
  }

  const handleAddRow = async (item) => {
    // console.log("setting add rows ", item);
    const found = rows.some(el => el.product.id === item.id);
    if (!found) {
      let calResult = calculateRow(item, null, 'purchase');
      // console.log("new calc - ", calResult);
      await addRowData({
        'product': item,
        'quantity': 1, 'product_discount_type': 'fixed',
        'product_discount_amount': 0.00, 'product_tax_amount': calResult.product_tax, 'discount_amount': 0.00,
        'sub_total': calResult.sub_total, 'unit_price': calResult.unit_price, 'price': calResult.price, 'first_price': calResult.price
      });
    }
    else {
      toast("Product already exists. Try another");
    }
  };

  const addRowData = async (rowObj) => {
    setRows([...rows, rowObj]);
  }

  const calculateSummary = async () => {
    console.log("summary calc - ", rows);
    let totalAmount = 0.00;
    if (rows.length > 0) {
      let grandSubTotal = Number(await getGrandSubTotal());
      totalAmount = grandSubTotal;
      if (formData) {
        let tempObj = { ...formData };
        let discountPercentage = tempObj.discount_percentage ?? 0;
        let discountAmount = 0.00;
        let taxAmount = 0.00;
        let shippingAmount = 0.00;

        if (discountPercentage > 0) {
          discountAmount = grandSubTotal * (discountPercentage / 100);
          tempObj['discount_amount'] = discountAmount;
          //tempObj['discount_percentage'] = discountPercentage;
        }
        let taxPercentage = tempObj.tax_percentage ?? 0;
        if (taxPercentage > 0) {
          taxAmount = (grandSubTotal - discountAmount) * (taxPercentage / 100);
          tempObj['tax_amount'] = Number(taxAmount);
          //tempObj['tax_percentage'] = taxPercentage;
        }
        if (tempObj.shipping_amount) {
          shippingAmount = Number(tempObj.shipping_amount);
          //tempObj['shipping_amount'] = shippingAmount;
        }
        totalAmount = grandSubTotal - discountAmount + taxAmount + shippingAmount;
        tempObj['total_amount'] = totalAmount = Number(totalAmount);
        setFormData(tempObj);
      }
    }
    setGrandTotal(totalAmount);
  }

  const handleRemove = (e, idx) => {
    e.preventDefault();
    console.log("setting delete rows ", rows, idx, dataLoaded);
    if (rows.length > 0 && dataLoaded) {
      console.log("deleting row");
      const tempRows = [...rows]; // to avoid  direct state mutation
      tempRows.splice(idx, 1);
      setRows(tempRows);
    }
  };

  const updateState = (e) => {
    console.log(rows);
    let prope = e.target.attributes.collumn.value; // the custom collumn attribute
    let index = e.target.attributes.index.value; // index of state array -rows

    let fieldValue = e.target.value; // value
    console.log(prope, index, fieldValue);
    const tempRows = [...rows]; // avoid direct state mutation
    const tempObj = tempRows[index]; // copy state object at index to a temporary object
    tempObj[prope] = fieldValue; // modify temporary object
    // update sub total
    let product_discount_amount = tempObj['product_discount_amount'];
    if (prope == "product_discount_amount" || prope == "product_discount_type") {
      if (tempObj['product_discount_type'] == "percentage") {
        product_discount_amount = (tempObj['first_price'] * tempObj['product_discount_amount']) / 100;
      }
      else {
        product_discount_amount = tempObj['product_discount_amount'];
      }
      tempObj['discount_amount'] = product_discount_amount;
      tempObj['price'] = tempObj['first_price'] - product_discount_amount;
      tempObj['sub_total'] = tempObj['price'] * tempObj['quantity'];
    }
    else if (prope == 'quantity') {
      if (fieldValue > 0) {
        tempObj['sub_total'] = tempObj['price'] * fieldValue;
      }
      // let calResult = calculateRow(tempObj, new_price, 'purchase');
      // console.log("new calc - ", tempObj, calResult);
      // tempObj['product_tax_amount'] = calResult.product_tax;
      // tempObj['sub_total'] = calResult.sub_total;
      // tempObj['unit_price'] = calResult.unit_price;
    }
    tempObj['sub_total'] = Math.round(tempObj['sub_total'] * 100) / 100;
    // return object to rows clone
    tempRows[index] = tempObj;
    console.log(tempRows);
    setRows(tempRows); // update state
    // calculateSummary();
  };

  const getGrandSubTotal = async () => {
    let total = 0;
    rows.map((e, key) => {
      total = total + e.sub_total;
      return total;
    });
    return total;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // let userFormData = {
    //   date: e.target.inputDate.value,
    //   supplier_id: e.target.supplier_id.value,
    //   payment_method: e.target.payment_method.value,
    //   note: e.target.note.value,
    //   products: rows
    // }
    formData['products'] = rows;
    console.log(formData);
    if (formData.id > 0) {
      update(formData);
    } else {
      add(formData);
      // setFormData(null);
    }
  };

  // Create a new user
  const add = async (userData) => {
    try {
      const response = await axios.post('/purchase-order', userData);
      const data = await response.data;
      console.log(data);
      toast(data.message);
      if (data.status) {
        navigate('/dashboard/purchases');
      }
    } catch (error) {
      console.error('Error adding user:', error);
    }
  };

  // Update an existing user
  const update = async (userData) => {
    try {
      const response = await axios.put(`/purchase-order/${formData.id}`, userData);
      const data = await response.data;
      console.log(data);
      if (data.status) {
        toast("Purchase Order updated");
      }
      // setFormData(data.data);
    } catch (error) {
      console.error('Error updating:', error);
    }
  };

  const updateFormData = (e) => {
    let prope = e.target.attributes.name.value;
    let fieldValue = e.target.value;
    console.log(prope, fieldValue);
    const tempObj = { ...formData }; // copy state object at index to a temporary object
    tempObj[prope] = fieldValue; // modify temporary object
    setFormData(tempObj);
    console.log(formData);
    if (prope == "discount_percentage" || prope == "tax_percentage" || prope == "shipping_amount") {
      const tempObjF = { ...formCalFields };
      tempObjF[prope] = fieldValue; // modify temporary object
      setFormCalFields(tempObjF);
      console.log(formCalFields);
    }
  }

  const columns = useMemo(
    () => [
      {
        name: "Product",
        cell: (row, index) => (
          <>
            {row.product.product_name}
            <span className="badge badge-success" style={{ marginLeft: "5px" }}>{row.product.product_code}</span>
          </>
        ),
        wrap: true
      },
      {
        name: "Unit Price (Rs)",
        selector: row => row.price
      },
      {
        name: "Stock",
        selector: row => row.product.product_quantity + ' ' + row.product.product_unit
      },
      {
        name: "Quantity",
        cell: (row, index) => (
          <input type="number" collumn="quantity" name="quantities[]" min="1" className="form-control" value={row.quantity}
            index={index} onChange={(e) => updateState(e)} />
        )
      },
      {
        name: "Discount",
        width: "220px",
        cell: (row, index) => (
          <>
            <select className="form-control" style={{ marginRight: "10px" }} collumn="product_discount_type" name="discount_types[]"
              index={index} value={row.product_discount_type} onChange={(e) => updateState(e)} >
              {
                discountTypeData.map((e, key) => {
                  return <option value={e.id}>{e.name}</option>;
                })
              }
            </select>

            <input type="number" collumn="product_discount_amount" name="discounts[]" className="form-control" value={row.product_discount_amount}
              index={index} onChange={(e) => updateState(e)} />
          </>
        )
      },
      {
        name: "Discount Amount",
        cell: (row, index) => (
          <>
            {(row.discount_amount)}
          </>
        )
      },
      {
        name: "Tax",
        selector: row => row.product_tax_amount
      },
      {
        name: "Sub Total",
        selector: row => row.sub_total
      },
      {
        name: "Actions",
        // eslint-disable-next-line react/button-has-type
        cell: (row, index) => (
          <span>
            <i className='fas fa-trash-alt' style={{ fontSize: "30px", color: "red" }} onClick={(e) => handleRemove(e, index)}></i>
          </span>
        ),
        ignoreRowClick: true
      }
    ],
    [rows]
  );

  return (
    <>
      <Link to='/dashboard/purchases' className="btn btn-primary" >Back</Link>
      <hr />
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">Purchase Order Form</h6>
        </div>
        <div className="card-body">
          <ProductSearch func={handleAddRow} />
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="supplier_id">Supplier <span className="text-danger">*</span></label>
                <select className="form-control" value={formData.supplier_id} onChange={e => updateFormData(e)} name="supplier_id" required>
                  <option value=''>--Select--</option>
                  {
                    suppliersData.map((e, key) => {
                      return <option key={e.supplier_name} value={e.id}>{e.supplier_name}</option>;
                    })
                  }
                </select>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="date">Date <span className="text-danger">*</span></label>
                <input type="date" className="form-control" value={formData.date} onChange={e => updateFormData(e)} name="date" required />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <DataTable
                  title="Products List"
                  columns={columns}
                  data={rows}
                />
              </div>
            </div>
            <div className="row justify-content-md-end">
              <div className="col-md-4">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <tbody>
                      <tr>
                        <th>Tax ({formData.tax_percentage}%)</th>
                        <td>(+) {formData ? formatNumber(formData.tax_amount) : 0.00} </td>
                      </tr>
                      <tr>
                        <th>Discount ({formData.discount_percentage}%)</th>
                        <td>(-) {formData ? formatNumber(formData.discount_amount) : 0.00}</td>
                      </tr>
                      <tr>
                        <th>Shipping</th>
                        <td>(+) {formatNumber(formData.shipping_amount)}</td>
                      </tr>
                      <tr>
                        <th>Grand Total</th>
                        <th>
                          (=) {formatNumber(grandTotal)}
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-lg-4">
                <div className="form-group">
                  <label htmlFor="tax_percentage">Tax (%)</label>
                  <input type="number" className="form-control" value={formData.tax_percentage} onChange={e => updateFormData(e)} name="tax_percentage" min={0} max={100} />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label htmlFor="discount_percentage">Discount (%)</label>
                  <input type="number" className="form-control" name="discount_percentage" min={0} max={100} value={formData.discount_percentage} onChange={e => updateFormData(e)} />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label htmlFor="shipping_amount">Shipping</label>
                  <input type="number" className="form-control" name="shipping_amount" min="0" value={formData.shipping_amount} onChange={e => updateFormData(e)} step="0.01" />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-lg-4">
                <div className="form-group">
                  <label htmlFor="status">Status <span className="text-danger">*</span></label>
                  <select className="form-control" name="status" required value={formData.status} onChange={e => updateFormData(e)}>
                    <option value="Pending">Pending</option>
                    <option value="Ordered">Ordered</option>
                    <option value="Completed">Completed</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="from-group">
                  <div className="form-group">
                    <label htmlFor="payment_method">Payment Method <span
                      className="text-danger">*</span></label>
                    <select className="form-control" name="payment_method" required value={formData.payment_method} onChange={e => updateFormData(e)}>
                      <option value="Cash">Cash</option>
                      <option value="Credit Card">Credit Card</option>
                      <option value="Bank Transfer">Bank Transfer</option>
                      <option value="Cheque">Cheque</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label htmlFor="paid_amount">Amount Paid <span className="text-danger">*</span></label>
                  <div className="input-group">
                    <input type="number" className="form-control" value={formData.paid_amount} onChange={e => updateFormData(e)} name="paid_amount" min="0.00" required />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label htmlFor="note">Notes</label>
                <textarea className="form-control" value={formData.note} onChange={e => updateFormData(e)} name="note" ></textarea>
              </div>
            </div>
            {rows.length > 0 &&
              <button type="submit" className="btn btn-primary">{formData.id > 0 ? ('Update') : ('Add')}</button>
            }
          </form>
        </div>
      </div>
    </>
  )
}

export default PurchaseForm;