import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { buildApiErrors } from "../../helpers/common";

const SettingForm = () => {
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    fetchUnit();
  }, []);

  const fetchUnit = async () => {
    try {
      const response = await axios.get(`/setting`);
      setFormData(response.data.data);
    }
    catch (error) {
      console.log(error);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const latestFormData = {
      company_name: e.target.inputName.value,
      company_email: e.target.inputEmail.value,
      company_phone: e.target.inputPhone.value,
      notification_email: e.target.inputNEmail.value,
      company_address: e.target.inputAddr.value
    }
    update(latestFormData);
    setFormData(latestFormData);
  };

  // Update
  const update = async (userData) => {
    try {
      const response = await axios.post(`/setting`, userData);
      const data = await response.data;
      console.log(data);
      toast(data.message);
    } catch (error) {
      console.error('Error updating:', error);
      toast.error(buildApiErrors(error));
    }
  };

  return (
    <>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">General Settings</h6>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="inputName">Business Name</label>
                <input type="text" className="form-control" defaultValue={formData ? formData.company_name : ''} name="inputName" required />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="inputEmail">Business Email</label>
                <input type="text" className="form-control" defaultValue={formData ? formData.company_email : ''} name="inputEmail" required />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="inputNEmail">Notification Email</label>
                <input type="text" className="form-control" defaultValue={formData ? formData.notification_email : ''} name="inputNEmail" required />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="inputPhone">Business Phone</label>
                <input type="text" className="form-control" defaultValue={formData ? formData.company_phone : ''} name="inputPhone" required />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label htmlFor="inputAddr">Address</label>
                <textarea type="text" className="form-control" defaultValue={formData ? formData.company_address : ''} name="inputAddr" required></textarea>
              </div>
            </div>
            <button type="submit" className="btn btn-primary">Save Changes</button>
          </form>
        </div>
      </div>
    </>
  )
}

export default SettingForm;