import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { removeItem } from "../../helpers/common";
import moment from "moment";
import { Link, useOutletContext } from "react-router-dom";

const Customer = () => {
  const checkPermissionExists = useOutletContext();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // const [deleted, setDeleted] = useState([]);

  const fetchCustomers = async (page, size = perPage, search = '') => {
    try {
      setLoading(true);
      const response = await axios.post(
        `/customer/all?page=${page}&limit=${size}`, { search: search }
      );

      setData(response.data.data.data);
      setTotalRows(response.data.data.total);
      setLoading(false);
      setDataLoaded(true);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("useeffect", loading, dataLoaded);
    setDataLoaded(false);
    fetchCustomers(1);
  }, []);

  const handleDelete = useCallback(
    row => async () => {
      console.log(row);
      try {
        await axios.delete(`/customer/${row.id}`);
        fetchCustomers(1);

        // const response = await axios.post(
        //   `/customer/all?page=${currentPage}&limit=${perPage}`
        // );

        // setData(removeItem(response.data.data.data, row));
        // setTotalRows(totalRows - 1);
      } catch (error) {
        console.error(error);
      }
    },
    [currentPage, perPage, totalRows]
  );

  const columns = useMemo(
    () => [
      {
        name: "Name",
        selector: row => row.customer_name,
        wrap: true
        //sortable: true
      },
      {
        name: "Email Address",
        selector: row => row.customer_email,
        wrap: true
      },
      {
        name: "Phone Number",
        selector: row => row.customer_phone,
        wrap: true
      },
      {
        name: "City",
        selector: row => row.city
      },
      {
        name: "Country",
        selector: row => row.country
      },
      {
        name: "Address",
        selector: row => row.address
      },
      {
        name: "Created On",
        selector: row => row.created_at,
        format: row => moment(row.created_at).format('YYYY-MM-DD kk:mm:ss'),
        wrap: true
      },
      {
        name: "Actions",
        // eslint-disable-next-line react/button-has-type
        cell: row => <span>
          {
            checkPermissionExists("edit_customers") &&
            <Link to={`/dashboard/customer-form?form_id=${row.id}`} ><i className="fas fa-edit" style={{ fontSize: "30px", color: "green", marginRight: "15px" }}></i></Link>
          }
          {
            checkPermissionExists("delete_customers") &&
            <i className='fas fa-trash-alt' style={{ fontSize: "30px", color: "red" }} onClick={handleDelete(row)}></i>
          }
        </span >
      }
    ],
    [handleDelete]
  );

  const handlePageChange = page => {
    if (dataLoaded) {
      console.log("handlePageChange", loading);

      fetchCustomers(page);
      setCurrentPage(page);
    }
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    if (dataLoaded) {
      console.log("handlePerRowsChange", loading);
      fetchCustomers(page, newPerPage);
      setPerPage(newPerPage);
    }
  };

  const handleSearch = (event) => {
    let searchVal = event.target.value;
    console.log(searchVal);
    setSearch(searchVal);
    searchVal = searchVal.trim();
    if (searchVal.length == 0 || searchVal.length > 2) {
      fetchCustomers(1, perPage, searchVal);
    }
  };

  return (
    <>
      {/* <!-- Page Heading -->
      <h1 className="h3 mb-2 text-gray-800">Tables</h1>
      <p className="mb-4">DataTables is a third party plugin that is used to generate the demo table below.
        For more information about DataTables, please visit the
      </p> */}

      <div className="card shadow mb-4">
        {/* <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">DataTables Example</h6>
        </div> */}
        <div className="card-body">
          <div className="row">
            <div className="col">
              {
                checkPermissionExists("create_customers") &&
                <Link to='/dashboard/customer-form?form=0' className="btn btn-primary" >Add Customer</Link>
              }
            </div>
            <div className="col offset-md-4">
              <input className="form-control" type="text" name="inputSearch" value={search} placeholder="Search" onChange={handleSearch} />
            </div>
          </div>
          <DataTable
            title="Customers List"
            columns={columns}
            data={data}
            progressPending={loading}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            selectableRows
            onSelectedRowsChange={({ selectedRows }) => console.log(selectedRows)}
          />
        </div>
      </div>
    </>
  )
}

export default Customer;