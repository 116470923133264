export const buildApiErrors = (reject) => {
  const response = reject.response;
  var listItems = [];
  console.log(response);
  if (response.status == 422) {
    const errors = response.data.errors;
    // console.log(errors);
    for (var key in errors) {
      if (errors.hasOwnProperty(key)) {
        var valArr = errors[key];
        // console.log(valArr);
        valArr.map((val) => {
          // console.log(val);
          // if (!!val && val.constructor === Array) {
          //   listItems = val.map((value) => {
          //     return <li>{value}</li>;
          //   });
          // }
          listItems.push(val);
          // return <li>{val}</li>;
        });
      }
    }
  } else {
    listItems.push(response.data.message);
  }
  return (
    <>
      <ul>
        {listItems.map((val) => <li>{val}</li>)}
      </ul>
    </>);
}

export const removeItem = (array, item) => {
  const newArray = array.slice();
  newArray.splice(newArray.findIndex(a => a === item), 1);

  return newArray;
};

export const calculateRow = (product, new_price = null, cart_instance) => {
  let product_price = 0;
  if (new_price) {
    product_price = new_price;
  } else {
    if (cart_instance == 'purchase' || cart_instance == 'purchase_return') {
      product_price = product.product_cost;
      console.log("purchase calc", product_price);
    }
    else {
      product_price = product.product_price;
    }
  }
  let price = 0;
  let unit_price = 0;
  let product_tax = 0;
  let sub_total = 0;

  if (product.product_tax_type == 1) { // external
    price = product_price + (product_price * (product.product_order_tax / 100));
    unit_price = product_price;
    product_tax = product_price * (product.product_order_tax / 100);
    sub_total = product_price + (product_price * (product.product_order_tax / 100));
  } else if (product.product_tax_type == 2) { // internal
    price = product_price;
    unit_price = product_price - (product_price * (product.product_order_tax / 100));
    product_tax = product_price * (product.product_order_tax / 100);
    sub_total = product_price;
  } else {
    price = product_price;
    unit_price = product_price;
    product_tax = 0.00;
    sub_total = product_price;
  }

  return { 'price': price, 'unit_price': unit_price, 'product_tax': product_tax, 'sub_total': sub_total };
}

export const formatNumber = (num) => {
  return Number((Math.round(num * 100) / 100).toFixed(2));
};