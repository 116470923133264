import axios from "axios";
import { useEffect, useMemo, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";


const PurchaseView = () => {
  const printableArea = useRef();
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = useState(null);
  const [businessData, setBusinessData] = useState(null);
  const [rows, setRows] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  // const [grandTotal, setGrandTotal] = useState(0.00);

  // const navigate = useNavigate();
  const formID = searchParams.get("form_id");

  useEffect(() => {
    if (formID > 0) {
      setDataLoaded(false);
      fetchData(formID);
      fetchBusinessInfo();
    }
  }, [formID]);

  useEffect(() => {
    console.log("rows   d: ", rows);
    // calculateSummary();
  }, [rows]);

  useEffect(() => {
    console.log("formdata updated: ", formData, dataLoaded);
  }, [formData]);

  const fetchData = async (formID) => {
    try {
      const response = await axios.get(
        `/purchase-order/${formID}`
      );
      const results = await response.data.data;
      setFormData(results);
      setRows(results.purchase_details);
      // setGrandTotal(results.total_amount);
      console.log("setting initial rows ", results.purchase_details);
      setDataLoaded(true);
    } catch (error) {
      console.error(error);
    }
  }

  const fetchBusinessInfo = async () => {
    try {
      const response = await axios.get(`/setting`);
      setBusinessData(response.data.data);
    }
    catch (error) {
      console.log(error);
    }
  }

  const handlePrint = useReactToPrint({
    content: () => printableArea.current,
  });

  const columns = useMemo(
    () => [
      {
        name: "Product",
        cell: (row, index) => (
          <>
            {row.product.product_name}
            <span className="badge badge-success" style={{ marginLeft: "5px" }}>{row.product.product_code}</span>
          </>
        ),
        wrap: true
      },
      {
        name: "Unit Price (Rs)",
        selector: row => row.price
      },
      {
        name: "Stock",
        selector: row => row.product.product_quantity + ' ' + row.product.product_unit
      },
      {
        name: "Quantity",
        selector: row => row.quantity
      },
      {
        name: "Discount",
        width: "220px",
        selector: row => row.product_discount_type
      },
      {
        name: "Discount Amount",
        selector: row => row.discount_amount
      },
      {
        name: "Tax",
        selector: row => row.product_tax_amount
      },
      {
        name: "Sub Total",
        selector: row => row.sub_total
      },
    ],
    [rows]
  );

  return (
    <>
      <Link to='/dashboard/purchases' className="btn btn-primary" >Back</Link>
      <hr />
      <div className="card shadow mb-4" ref={printableArea}>
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">Purchase Order Details</h6>
          <div>
            Reference: <strong>{formData ? formData.reference : ''}</strong>
          </div>
          <button className="btn btn-sm btn-secondary mfs-auto mfe-1 d-print-none" onClick={handlePrint}>
            <i className="bi bi-printer"></i> Print
          </button>
        </div>
        <div className="card-body">
          <div className="row mb-4">
            <div className="col-sm-4 mb-3 mb-md-0">
              <h5 className="mb-2 border-bottom pb-2">Company Info:</h5>
              <div><strong>{businessData ? businessData.company_name : ''}</strong></div>
              <div>{businessData ? businessData.company_address : ''}</div>
              <div>Email: {businessData ? businessData.company_email : ''}</div>
              <div>Phone: {businessData ? businessData.company_phone : ''}</div>
            </div>

            <div className="col-sm-4 mb-3 mb-md-0">
              <h5 className="mb-2 border-bottom pb-2">Supplier Info:</h5>
              <div><strong>{formData ? formData.supplier_details.supplier_name : ''}</strong></div>
              <div>{formData ? formData.supplier_details.address : ''}</div>
              <div>Email: {formData ? formData.supplier_details.supplier_email : ''}</div>
              <div>Phone: {formData ? formData.supplier_details.supplier_phone : ''}</div>
            </div>

            <div className="col-sm-4 mb-3 mb-md-0">
              <h5 className="mb-2 border-bottom pb-2">Invoice Info:</h5>
              <div>Invoice: <strong>INV/{formData ? formData.reference : ''}</strong></div>
              <div>Date: {formData ? formData.date : ''}</div>
              <div>
                Status: <strong>{formData ? formData.status : ''}</strong>
              </div>
              <div>
                Payment Method: <strong>{formData ? formData.payment_method : ''}</strong><br />
                Payment Status: <strong>{formData ? formData.payment_status : ''}</strong>
              </div>
            </div>

          </div>
          <div className="table-responsive-sm">
            <DataTable
              title="Products List"
              columns={columns}
              data={rows}
            />
          </div>
          <div className="row">
            <div className="col-lg-4 col-sm-5 ml-md-auto">
              <table className="table">
                <tbody>
                  <tr>
                    <td className="left"><strong>Discount ({formData ? formData.discount_percentage : ''}%)</strong></td>
                    <td className="right">{formData ? formData.discount_amount : ''}</td>
                  </tr>
                  <tr>
                    <td className="left"><strong>Tax ({formData ? formData.tax_percentage : ''}%)</strong></td>
                    <td className="right">{formData ? formData.tax_amount : ''}</td>
                  </tr>
                  <tr>
                    <td className="left"><strong>Shipping</strong></td>
                    <td className="right">{formData ? formData.shipping_amount : ''}</td>
                  </tr>
                  <tr>
                    <td className="left"><strong>Grand Total</strong></td>
                    <td className="right"><strong>{formData ? formData.total_amount : ''}</strong></td>
                  </tr>
                  <tr>
                    <td className="left"><strong>Notes</strong></td>
                    <td className="right"><strong>{formData ? formData.note : ''}</strong></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PurchaseView;