import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import ProductSearch from "../product/ProductSearch";

const AdjustmentForm = () => {
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = useState(null);
  const [rows, setRows] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [typeData, setTypeData] = useState([{ "id": "sub", "name": "(-) Subtraction" }, { "id": "add", "name": "(+) Addition" }]);
  // const [categoriesData, setCategoriesData] = useState([]);
  // const [categorySelected, setCategorySelected] = useState("");
  // const [cost, setCost] = useState(0.00);

  const navigate = useNavigate();

  const formID = searchParams.get("form_id");
  useEffect(() => {
    if (formID > 0) {
      setDataLoaded(false);
      fetchData(formID);
    }
  }, [formID]);

  const fetchData = async (formID) => {
    try {
      const response = await axios.get(
        `/adjustment/${formID}`
      );
      const results = await response.data.data;
      setFormData(results);
      setRows(results.adjusted_products);
      console.log("setting initial rows ", results.adjusted_products);

      setDataLoaded(true);
      // console.log(categorySelected, unitSelected, response.data.data.is_active);
    } catch (error) {
      console.error(error);
    }
  }

  const handleAddRow = (item) => {
    console.log("setting add rows ", item);
    setRows([...rows, { 'product': item }]);
  };

  const handleRemove = (e, idx) => {
    e.preventDefault();
    console.log("setting delete rows ", rows, idx);
    if (rows.length > 0 && dataLoaded) {
      const tempRows = [...rows]; // to avoid  direct state mutation
      tempRows.splice(idx, 1);
      setRows(tempRows);
    }
  };

  const updateState = (e) => {
    console.log(rows);
    let prope = e.target.attributes.collumn.value; // the custom collumn attribute
    let index = e.target.attributes.index.value; // index of state array -rows

    let fieldValue = e.target.value; // value
    console.log(prope, index, fieldValue, rows);
    const tempRows = [...rows]; // avoid direct state mutation
    const tempObj = tempRows[index]; // copy state object at index to a temporary object
    tempObj[prope] = fieldValue; // modify temporary object
    // return object to rows` clone
    tempRows[index] = tempObj;
    console.log(tempRows);
    setRows(tempRows); // update state
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    let userFormData = {
      date: e.target.inputDate.value,
      note: e.target.note.value,
      products: rows
    }
    console.log(userFormData);
    if (formData) {
      update(userFormData);
    } else {
      add(userFormData);
      setFormData(null);
    }
  };

  // Create a new user
  const add = async (userData) => {
    try {
      const response = await axios.post('/adjustment', userData);
      const data = await response.data;
      console.log(data);
      toast(data.message);
      if (data.status) {
        navigate('/dashboard/adjustments');
      }
    } catch (error) {
      console.error('Error adding user:', error);
    }
  };

  // Update an existing user
  const update = async (userData) => {
    try {
      const response = await axios.put(`/adjustment/${formData.id}`, userData);
      const data = await response.data;
      console.log(data);
      if (data.status) {
        toast("Adjustment updated");
      }
      setFormData(data.data);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const columns = useMemo(
    () => [
      {
        name: "#",
        selector: (row, index) => index + 1
      },
      {
        name: "Product Name",
        selector: row => row.product.product_name,
        wrap: true
      },
      {
        name: "Code",
        selector: row => row.product.product_code
      },
      {
        name: "Stock",
        selector: row => row.product.product_quantity + ' ' + row.product.product_unit
      },
      {
        name: "Quantity",
        cell: (row, index) => (
          <input type="number" collumn="quantity" name="quantities[]" min="1" className="form-control" value={row.quantity}
            index={index} onChange={(e) => updateState(e)} />
        )
      },
      {
        name: "Type",
        cell: (row, index) => (
          <>
            <select className="form-control" collumn="type" name="types[]" index={index} value={row.type} onChange={(e) => updateState(e)} required>
              {
                typeData.map((e, key) => {
                  return <option value={e.id}>{e.name}</option>;
                })
              }
            </select>
          </>
        )
      },
      {
        name: "Actions",
        // eslint-disable-next-line react/button-has-type
        cell: (row, index) => (
          <span>
            <i className='fas fa-trash-alt' style={{ fontSize: "30px", color: "red" }} onClick={(e) => handleRemove(e, index)}></i>
          </span>
        ),
        ignoreRowClick: true
      }
    ],
    [rows]
  );

  return (
    <>
      <Link to='/dashboard/adjustments' className="btn btn-primary" >Back</Link>
      <hr />
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">Adjustment Form</h6>
        </div>
        <div className="card-body">
          <ProductSearch func={handleAddRow} />
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="inputRef">Reference</label>
                <input type="text" readOnly={true} className="form-control" defaultValue={formData ? formData.reference : ''} name="inputRef" />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="inputDate">Date</label>
                <input type="date" className="form-control" defaultValue={formData ? formData.date : ''} name="inputDate" required />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <DataTable
                  title="Products List"
                  columns={columns}
                  data={rows}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label htmlFor="note">Notes</label>
                <textarea className="form-control" defaultValue={formData ? formData.note : ''} name="note" required></textarea>
              </div>
            </div>
            <button type="submit" className="btn btn-primary">{formData ? ('Update') : ('Add')}</button>
          </form>
        </div>
      </div>
    </>
  )
}

export default AdjustmentForm;