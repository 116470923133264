import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { buildApiErrors } from "../../helpers/common";

const UnitForm = () => {
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = useState(null);
  const navigate = useNavigate();

  const formID = searchParams.get("form_id");
  useEffect(() => {
    if (formID > 0) {
      fetchUnit(formID);
    }
  }, [formID]);

  const fetchUnit = async (formID) => {
    const response = await axios.get(
      `/unit/${formID}`
    );
    setFormData(response.data.data);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const latestFormData = {
      short_name: e.target.inputCode.value,
      name: e.target.inputName.value
    }
    if (formData) {
      update(latestFormData);
    } else {
      add(latestFormData);
    }
    setFormData(null);
  };

  // Create a new user
  const add = async (userData) => {
    try {
      const response = await axios.post('/unit', userData);
      const data = await response.data;
      console.log(data);
      toast(data.message);
      if (data.status) {
        navigate('/dashboard/units');
      }
    } catch (error) {
      console.error('Error adding user:', error);
      toast.error(buildApiErrors(error));
    }
  };

  // Update an existing user
  const update = async (userData) => {
    try {
      const response = await axios.put(`/unit/${formData.id}`, userData);
      const data = await response.data;
      console.log(data);
      setFormData(data.data);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  return (
    <>
      <Link to='/dashboard/units' className="btn btn-primary" >Back</Link>
      <hr />
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">Unit Form</h6>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="inputName">Name</label>
                <input type="text" className="form-control" defaultValue={formData ? formData.name : ''} name="inputName" placeholder="Name" required />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="inputCode">Short Name</label>
                <input type="text" className="form-control" defaultValue={formData ? formData.short_name : ''} name="inputCode" placeholder="Short Name" required />
              </div>
            </div>
            <button type="submit" className="btn btn-primary">{formData ? ('Update') : ('Add')}</button>
          </form>
        </div>
      </div>
    </>
  )
}

export default UnitForm;