import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import { toast } from "react-toastify";
import { buildApiErrors } from "../helpers/common";
import axios from "axios";

const Login = () => {
  const { setToken } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.username.value;
    const password = e.target.pwd.value;
    try {
      const response = await axios.post("/auth/login", {
        email,
        password,
      });
      if (response.data.access_token) {
        await setToken(response.data.access_token);
        //await setUserRoles(response.data.access_token);
        toast.success("Authentication Success!");
        navigate("/dashboard", { replace: true });
      }
      else {
        toast.error("Invalid action");
      }
    } catch (error) {
      toast.error(buildApiErrors(error));
      setToken();
    }
  };

  const setUserRoles = async (token) => {
    var config = {
      headers: { 'Authorization': 'Bearer ' + token }
    }
    try {
      const response = await axios.post("/auth/me", {});
      console.log(response);
      if (response.data.status) {
        sessionStorage.setItem("user_data", JSON.stringify(response.data));
      }
      else {
        sessionStorage.setItem("user_data", null);
      }
    } catch (error) {
      sessionStorage.setItem("user_data", null);
    }
  }

  return (
    <>
      <div className="container">
        <div className="row justify-content-center">

          <div className="col-xl-6 col-lg-6 col-md-6">

            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                <div className="p-5">
                  <div className="text-center">
                    <h1 className="h4 text-gray-900 mb-4">Reyns POS Login</h1>
                  </div>
                  <form className="user" onSubmit={handleSubmit}>
                    <div className="form-group">
                      <input type="email" className="form-control form-control-user"
                        name="username" aria-describedby="emailHelp"
                        placeholder="Enter Email Address..." required />
                    </div>
                    <div className="form-group">
                      <input type="password" className="form-control form-control-user"
                        name="pwd" placeholder="Password" required />
                    </div>
                    {/* <div className="form-group">
                          <div className="custom-control custom-checkbox small">
                            <input type="checkbox" className="custom-control-input" id="customCheck" />
                            <label className="custom-control-label" htmlFor="customCheck">Remember
                              Me</label>
                          </div>
                        </div> */}
                    <button className="btn btn-primary btn-user btn-block">
                      Login
                    </button>
                    {/* <hr /> */}
                    {/* <a href="index.html" className="btn btn-google btn-user btn-block">
                          <i className="fab fa-google fa-fw"></i> Login with Google
                        </a>
                        <a href="index.html" className="btn btn-facebook btn-user btn-block">
                          <i className="fab fa-facebook-f fa-fw"></i> Login with Facebook
                        </a> */}
                  </form>
                  <hr />
                  <div className="text-center">
                    <Link to="/forgot-password" className="small">Forgot Password?</Link>
                  </div>
                  {/* <div className="text-center">
                        <a className="small" href="register.html">Create an Account!</a>
                      </div> */}
                </div>


              </div>
            </div>

          </div>

        </div>

      </div>
    </>
  );
};

export default Login;