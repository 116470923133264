import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import { ProtectedRoute } from "./ProtectedRoute";
import Login from "../pages/Login";
import Dashboard from "../components/dashboard/Dashboard";
import Customer from "../components/customer/Customer";
import CustomerForm from "../components/customer/CustomerForm";
import Supplier from "../components/supplier/Supplier";
import SupplierForm from "../components/supplier/SupplierForm";
import User from "../components/user/User";
import UserForm from "../components/user/UserForm";
import ProductCategory from "../components/product-category/ProductCategory";
import ProductCategoryForm from "../components/product-category/ProductCategoryForm";
import Product from "../components/product/Product";
import ProductForm from "../components/product/ProductForm";
import Unit from "../components/unit/Unit";
import UnitForm from "../components/unit/UnitForm";
import SettingForm from "../components/setting/SettingForm";
import ExpenseCategory from "../components/expense-category/ExpenseCategory";
import ExpenseCategoryForm from "../components/expense-category/ExpenseCategoryForm";
import Expense from "../components/expense/Expense";
import ExpenseForm from "../components/expense/ExpenseForm";
import Adjustment from "../components/stock-adjustment/Adjustment";
import AdjustmentForm from "../components/stock-adjustment/AdjustmentForm";
import Profile from "../components/dashboard/Profile";
import Role from "../components/role/Role";
import RoleForm from "../components/role/RoleForm";
import PurchaseOrder from "../components/purchase-order/PurchaseOrder";
import PurchaseForm from "../components/purchase-order/PurchaseForm";
import PurchaseView from "../components/purchase-order/PurchaseView";
import POPayments from "../components/purchase-order/payments/POPayments";
import POPaymentForm from "../components/purchase-order/payments/POPaymentForm";
import Sales from "../components/sale/Sales";
import SaleForm from "../components/sale/SaleForm";
import SaleView from "../components/sale/SaleView";

const Routes = () => {
  const { token } = useAuth();

  // Define public routes accessible to all users
  const routesForPublic = [
    {
      path: "/",
      element: <Login />,
    },
    // {
    //   path: "/service",
    //   element: <div>Service Page</div>,
    // },
    // {
    //   path: "/about-us",
    //   element: <div>About Us</div>,
    // },
    {
      path: "*",
      element: <div className="container"><center><h2>Invalid Action Page</h2></center></div>,
    }
  ];

  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly = [
    {
      path: "/dashboard",
      element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
      children: [
        {
          path: "",
          element: <Dashboard />,
        },
        {
          path: "customers",
          element: <Customer />,
        },
        {
          path: "customer-form",
          element: <CustomerForm />,
        },
        {
          path: "suppliers",
          element: <Supplier />,
        },
        {
          path: "supplier-form",
          element: <SupplierForm />,
        },
        {
          path: "users",
          element: <User />,
        },
        {
          path: "user-form",
          element: <UserForm />,
        },
        {
          path: "product-categories",
          element: <ProductCategory />,
        },
        {
          path: "product-category-form",
          element: <ProductCategoryForm />,
        },
        {
          path: "products",
          element: <Product />,
        },
        {
          path: "product-form",
          element: <ProductForm />,
        },
        {
          path: "units",
          element: <Unit />,
        },
        {
          path: "unit-form",
          element: <UnitForm />,
        },
        {
          path: "setting-form",
          element: <SettingForm />,
        },
        {
          path: "expense-categories",
          element: <ExpenseCategory />,
        },
        {
          path: "expense-category-form",
          element: <ExpenseCategoryForm />,
        },
        {
          path: "expenses",
          element: <Expense />,
        },
        {
          path: "expense-form",
          element: <ExpenseForm />,
        },
        {
          path: "adjustments",
          element: <Adjustment />,
        },
        {
          path: "adjustment-form",
          element: <AdjustmentForm />,
        },
        {
          path: "profile",
          element: <Profile />,
        },
        {
          path: "roles-permissions",
          element: <Role />,
        },
        {
          path: "role-form",
          element: <RoleForm />,
        },
        {
          path: "purchases",
          element: <PurchaseOrder />,
        },
        {
          path: "purchase-order-form",
          element: <PurchaseForm />,
        },
        {
          path: "purchase-order",
          element: <PurchaseView />,
        },
        {
          path: "purchase-order-payments",
          element: <POPayments />,
        },
        {
          path: "purchase-order-payments-form",
          element: <POPaymentForm />,
        },
        {
          path: "sales",
          element: <Sales />,
        },
        {
          path: "sale-form",
          element: <SaleForm />,
        },
        {
          path: "sale",
          element: <SaleView />,
        },
        {
          path: "sale-payments",
          element: <POPayments />,
        },
        {
          path: "sale-payments-form",
          element: <POPaymentForm />,
        }
      ],
    },
  ];

  // Define routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly = [
    // {
    //   path: "/",
    //   element: <div>Home Page</div>,
    // },
    {
      path: "/",
      element: <Login />,
    },
  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    ...routesForPublic,
    ...(!token ? routesForNotAuthenticatedOnly : []),
    ...routesForAuthenticatedOnly,
  ]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default Routes;