import { ToastContainer, toast } from 'react-toastify';
import AuthProvider from './provider/authProvider';
import Routes from "./routes";
import "./App.css";
// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

// toast.configure();

function App() {
  return (
    <>
      <ToastContainer />

      <AuthProvider>
        <Routes />
      </AuthProvider>
    </>
  );
}

export default App;
