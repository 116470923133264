import axios from 'axios';

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL, //'http://127.0.0.1:8000/api/',
  //mode: "no-cors",
  //withCredentials: true, // <-- ADD THIS
  //credentials: 'include',
  // headers: {
  //   'Content-type': 'application/json',
  //   Accept: 'application/json'
  // },
});

// Add a request interceptor
httpClient.interceptors.request.use(
  (config) => {
    console.log("token from interceptorss");
    const token = sessionStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default httpClient;