import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

const ExpenseForm = () => {
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = useState(null);
  const [categoriesData, setCategoriesData] = useState([]);
  const [categorySelected, setCategorySelected] = useState("");
  const [cost, setCost] = useState(0.00);

  const navigate = useNavigate();

  const formID = searchParams.get("form_id");
  useEffect(() => {
    fetchCategories();
    if (formID > 0) {
      fetchData(formID);
    }
  }, [formID]);

  const fetchData = async (formID) => {
    try {
      const response = await axios.get(
        `/expense/${formID}`
      );
      setFormData(response.data.data);
      setCategorySelected(response.data.data.category.id);
      setCost(response.data.data.amount);
      // console.log(categorySelected, unitSelected, response.data.data.is_active);
    } catch (error) {
      console.error(error);
    }
  }

  const fetchCategories = async () => {
    try {
      const response = await axios.post(`/expense-category/all?limit=100`);
      const data = response.data.data.data;
      console.log(data);
      setCategoriesData(data);
      if (formID < 1) {
        setCategorySelected(data[0].id);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let userFormData = {
      date: e.target.inputDate.value,
      amount: e.target.inputAmount.value,
      details: e.target.inputDetails.value,
      category_id: e.target.category.value
    }
    if (formData) {
      update(userFormData);
    } else {
      add(userFormData);
      setFormData(null);
    }
  };

  // Create a new user
  const add = async (userData) => {
    try {
      const response = await axios.post('/expense', userData);
      const data = await response.data;
      console.log(data);
      toast(data.message);
      if (data.status) {
        navigate('/dashboard/expenses');
      }
    } catch (error) {
      console.error('Error adding user:', error);
    }
  };

  // Update an existing user
  const update = async (userData) => {
    try {
      const response = await axios.put(`/expense/${formData.id}`, userData);
      const data = await response.data;
      console.log(data);
      if (data.status) {
        toast("Expense updated");
      }
      setFormData(data.data);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  return (
    <>
      <Link to='/dashboard/expenses' className="btn btn-primary" >Back</Link>
      <hr />
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">Expense Form</h6>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="category">Category <span className="text-danger">*</span></label>
                <select className="form-control" value={categorySelected} onChange={e => setCategorySelected(e.target.value)} name="category" required>
                  {
                    categoriesData.map((e, key) => {
                      return <option key={e.category_name} value={e.id}>{e.category_name}</option>;
                    })
                  }
                </select>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="inputDate">Date</label>
                <input type="date" className="form-control" defaultValue={formData ? formData.date : ''} name="inputDate" required />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="inputAmount">Amount</label>
                <input type="number" className="form-control" value={cost} name="inputAmount" min={0} onChange={e => setCost(e.target.value)} required />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="inputDetails">Details</label>
                <textarea className="form-control" defaultValue={formData ? formData.details : ''} name="inputDetails" required></textarea>
              </div>
            </div>
            <button type="submit" className="btn btn-primary">{formData ? ('Update') : ('Add')}</button>
          </form>
        </div>
      </div>
    </>
  )
}

export default ExpenseForm;