import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import { Link, useOutletContext } from "react-router-dom";

const Unit = () => {
  const checkPermissionExists = useOutletContext();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const fetchUnits = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/unit/all`);
      setData(response.data.data);
      setLoading(false);
      setDataLoaded(true);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("useeffect", loading, dataLoaded);
    setDataLoaded(false);
    fetchUnits();
  }, []);

  const handleDelete = useCallback(
    row => async () => {
      console.log(row);
      try {
        await axios.delete(`/unit/${row.id}`);
        fetchUnits();
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const columns = useMemo(
    () => [
      {
        name: "Name",
        selector: row => row.name,
        wrap: true
        //sortable: true
      },
      {
        name: "Short Name",
        selector: row => row.short_name,
        wrap: true
      },
      {
        name: "Created On",
        selector: row => row.created_at,
        format: row => moment(row.created_at).format('YYYY-MM-DD kk:mm:ss'),
        wrap: true
      },
      {
        name: "Actions",
        // eslint-disable-next-line react/button-has-type
        cell: row => <span>
          {
            checkPermissionExists("access_units") && <>
              <Link to={`/dashboard/unit-form?form_id=${row.id}`} ><i className="fas fa-edit" style={{ fontSize: "30px", color: "green", marginRight: "15px" }}></i></Link>
              <i className='fas fa-trash-alt' style={{ fontSize: "30px", color: "red" }} onClick={handleDelete(row)}></i>
            </>
          }
        </span >
      }
    ],
    [handleDelete]
  );

  return (
    <>
      <div className="card shadow mb-4">
        <div className="card-body">
          <div className="row">
            <div className="col">
              {
                checkPermissionExists("access_units") &&
                <Link to='/dashboard/unit-form?form=0' className="btn btn-primary" >Add Unit</Link>
              }
            </div>
          </div>
          <DataTable
            title="Units List"
            columns={columns}
            data={data}
            progressPending={loading}
          />
        </div>
      </div>
    </>
  )
}

export default Unit;